import React from 'react';

const AlertaValidacionDimensiones = ({setShowAlertDimensions}) => {
    return (
        <>
            <div className='overlay_box'>
                <div className='alert_box'>
                    <p className='animate__animated animate__slideInDown'>La captura de tu identificación fue tomada de forma horizontal, por favor captura nuevamente tu identificación en vertical y alineada a las guías.</p>
                    <button type='button' onClick={() => {
                        setShowAlertDimensions(false);
                    }} className='btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s'>VOLVER A INTENTAR</button>
                </div>
            </div>
        </>
    );
}

export default AlertaValidacionDimensiones;
