import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle } from '../services/configStyle';
import { isIOS, isMobileSafari } from 'react-device-detect';

const PasosConfiguracion = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [apiKey, setApiKey] = useState('')

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
            //console.log(apiKey);
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        if (typeof MediaRecorder === 'undefined' || !navigator.getUserMedia) {
            //console.log("error");
        } else {
            history.push("/preparacion_identificacion");
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const verificarMediaRecorder = () => {
        window.location.reload();
    }

    return (
        <Fragment>
            <div className="main_gradient">
                <Header ruta="pasos_configuracion" />
                <div className="main_text_container">
                    <h1>Un paso sencillo pero muy importante</h1>
                    <p>Necesitamos <b>activar</b> en tu <b>dispositivo</b> una característica de <b>Safari</b> que nos permite mantener tu proceso con <b>más seguridad</b>.</p>
                    <p><small>Configuración -> <b>Safari</b> -> <b>Avanzado</b> -> <b>Experimental Features</b> -> <b>Media Recorder</b></small></p>
                    <ol className="nomrgnnopdng set_safari_sets">
                        <li><small className="set_li">Presiona ir a <b>configuración</b> y busca <b>Safari</b>.</small><br />
                            <img src="images/set_step_a.png" height="36" alt="" />
                        </li>
                        <li><small className="set_li">En <b>Safari</b> haz scroll hasta abajo a <b>Avanzado</b>.</small><br />
                            <img src="images/set_step_b.png" height="36" alt="" /></li>
                        <li><small className="set_li">Hasta abajo entra a <b>Experimental Features</b>.</small><br />
                            <img src="images/set_step_c.png" height="36" alt="" /></li>
                        <li><small className="set_li">Activa <b>Media Recorder</b> y <b>regresa aquí</b>.</small><br />
                            <img src="images/set_step_d.png" height="30" alt="" /></li>
                        <br />
                    </ol>
                </div>
                <div className="action_buttons noscroll_screen">
                    <Link to="/terminos" className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</Link>
                    {(isIOS || isMobileSafari) ? <a href="App-prefs://prefs:root=SAFARI" className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                        IR A CONFIGURACIÓN</a> : ("")}
                    <button type="button" onClick={e => verificarMediaRecorder()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                        Listo, opción activada</button>
                </div>
                <Footer />
            </div>
        </Fragment>
    )

}

export default PasosConfiguracion