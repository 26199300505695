import React, { useState, useEffect, useRef, Fragment } from "react";
import { isMobileOnly, withOrientationChange, isMobile, isAndroid } from 'react-device-detect';

let Footer = (props) => {

    const [stateStyleA, setStateStyleA] = useState(true);
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [anchoPantala] = useState(window.screen.width);
    const { isLandscape } = props;
    const footerRef = useRef(null)

    useEffect(() => {
        //console.log("mobile" + document.documentElement.clientHeight);

        let altura = document.documentElement.clientHeight;
        if (isMobileOnly && (altura > 450)) {
            setStateStyleA(false);
        }
        if (hayCookies) {
            let bloqueo = localStorage.getItem("bloqueo");
            let newDate = new Date().getTime()
            if (bloqueo == 'true') {
                //console.log("BloqueoTrue: " + bloqueo);
                if (newDate >= localStorage.getItem("time")) {
                    localStorage.setItem("bloqueo", false);
                    localStorage.setItem("time", 0);
                } else {
                    let ruta = window.location.pathname;
                    let url = "/" + localStorage.getItem("apikeyOtorgante");
                    //console.log(ruta,url);
                    if (ruta == url) {
                        //console.log("entre a desbloqueo load");
                        localStorage.setItem("bloqueo", false);
                        localStorage.setItem("time", 0);
                    }
                }
            }
        }

        let footer = footerRef.current;
        if (isMobile) {
            if (window.innerHeight >= 630 && window.innerWidth >= 360) {
                footer.style.position = 'fixed';
            } else {
                footer.style.position = 'relative';
            }
        } else {
            if (window.innerHeight > 750) {
                footer.style.position = 'fixed';
            } else {
                footer.style.position = 'relative';
            }
        }
    }, [])

    /* const revisarAlto = () => {
        if (isMobile) {
            if (isAndroid) {
                if (window.screen.height === anchoPantala) {
                    //console.log("true");
                    return true
                } else {
                    //console.log("false");
                    return false
                }
            } else {
                //console.log("SCREEN:"+window.screen.width + " " + window.screen.height);
                //console.log("INNER:"+window.innerWidth + " " + window.innerHeight);
                if (window.innerWidth !== window.screen.width) {
                    //console.log("true");
                    return true
                } else {
                    //console.log("false");
                    return false
                }
            }
        }
        else {
            function updateSize() {
                //console.log(window.innerWidth + " " + window.innerHeight);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return true;
        }
    } */

    const revisarTiempo = () => {
        //console.log("entre");
        let bloqueo = localStorage.getItem("bloqueo");
        //console.log("xD: " + bloqueo);
        let newDate = new Date().getTime()
        //console.log(newDate);

        if (bloqueo == 'true') {
            //console.log("BloqueoF: " + bloqueo);
            if (newDate >= localStorage.getItem("time")) {
                localStorage.setItem("bloqueo", false);
                localStorage.setItem("time", 0);

            } else {
                let ruta = window.location.pathname;
                let url = "/" + localStorage.getItem("apikeyOtorgante");
                if (ruta != url) {
                    window.location.href = url;
                } else {
                    //console.log("entre a desbloqueo");
                    localStorage.setItem("bloqueo", false);
                    localStorage.setItem("time", 0);
                }
            }
        }
    }

    return (
        <>
            <div ref={footerRef} className={["powered_by d-flex", ((stateStyleA) ? "full_screen_spce" : "full_screen_spce2")].join(" ")}><img src={process.env.PUBLIC_URL + '/images/powered_by_dco.png'} alt="" /></div>
            {
                (isLandscape) && (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p>Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>
                )
            }
            {(hayCookies) ?
                (localStorage.getItem("bloqueo") == 'true') ? (<div className="err_finale  animate__animated animate__slideInUp">
                    <div className="center_checks">
                        <h5>Proceso no exitoso</h5>
                        <p>Has superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                        <p>Si deseas mayor información no dudes en contactarnos con el <b>Folio</b> de proceso <b>{(localStorage.getItem("uuidUser")).split("-")[0]}</b> </p>
                        <br />
                        <div className="action_buttons noscroll_screen">
                            <button onClick={e => revisarTiempo()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                                ENTENDIDO</button>
                        </div>
                    </div>
                </div>) : ("")
                : ("")}
        </>

    );
}
Footer = withOrientationChange(Footer)
export default Footer;