import React from 'react';
import {  withOrientationChange } from 'react-device-detect';

const Index = (props) => {
    const { isLandscape } = props

    return (
        <>
            {(isLandscape) &&
                <div className="rotate_device">
                    <div className="center_info_bx">
                        <img src="images/rotate_device.svg" height="100" alt="" />
                        <p>Por favor usa tu dispositivo en vertical<br />
                            <small>Gira tu dispositivo para continuar</small>
                        </p>

                    </div>
                </div>}
        </>
    );
}

export default withOrientationChange(Index)
