import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { obtenerEstadoEnlace } from '../services/api';
import { evento, validarParametroOtorgante } from "../services/data";


const PdfTokenResult = () => {
    const [enlace] = useState(localStorage.getItem("enlace"));
    const history = useHistory();

    const descargaArchivo = () => {
        let tipo = '';
        let informacion = {};
        obtenerEstadoEnlace(enlace).then((response) => {
            if (response.status === 200) {
                //console.log("descarga");
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = enlace;
                a.download = "pdf_autorizacion.pdf";
                document.body.appendChild(a);
                a.click();
            }
            evento('Descargar Archivo', tipo, informacion, true);

        }).catch((error) => {
            console.error(error);
            //console.log("no descargar", error);
            const dataOtorganteLS = localStorage.getItem("dataOtorgante")
            const dataOtorgante = JSON.parse(dataOtorganteLS)
            let step = '/pdf_video_token'
            if (validarParametroOtorgante(dataOtorgante, 'DISABLE_PDF_LIVENESS') === true) {
                step = '/preparacion_liveness_pdf'
            } else if (validarParametroOtorgante(dataOtorgante, 'DISABLE_PDF_LIVENESS') === false) {
                if (validarParametroOtorgante(dataOtorgante, 'DISABLE_PDF_VIDEO_TOKEN')) {
                    step = '/selfie_pdf'
                }
            } else {
                if (validarParametroOtorgante(dataOtorgante, 'DISABLE_PDF_VIDEO_TOKEN')) {
                    step = '/selfie_pdf'
                }
            }
            evento('Error Descargar Archivo', tipo, informacion, true);
            history.push(step)

        });
    };

    return (<div className="main_gradient">
        <Header ruta="pdf_token_result" />
        <div className="main_text_container">
            <h1>Resultado de proceso </h1>
            <p>Tu documento <b>está listo</b>, puedes <b>verlo</b> o <b>descargarlo</b> en este momento.</p>
            <br /><br />
            <small>*<b>Respalda</b> tu documento, solo se puede <b>consultar una vez</b>.</small>
        </div>
        <div className="action_buttons noscroll_screen">
            <button type="button" onClick={(e) => { descargaArchivo(); }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">DESCARGAR DOCUMENTO</button>
        </div>
        <Footer />
    </div>)
}

export default PdfTokenResult