import React, { useEffect } from "react";
import Header from "../components/headerNA";
import Footer from "../components/footer";
const PdfTokenInstructions = () => {

    useEffect(() => {
    }, [])

    useEffect(() => {
    }, [])


    return (
        <div className="main_gradient">
            <Header ruta="pdf_token_intructions" />

            <div className="main_text_container">

                <h1 className="animate__animated animate__fadeIn animate__delay-1s">Resultado de proceso</h1>

                <p className="animate__animated animate__fadeIn animate__delay-2s">
                    Para ver o descargar el resultado de tu proceso <b>asegúrate</b> que tu dispositivo tenga <b>acceso a la cámara</b> y que te encuentres en un <b>lugar silencioso</b>:
	        <br /><br />
                    <small>Vencimiento: <b>12/06/2020</b></small>
                </p>
            </div>
            <div className="action_buttons noscroll_screen">

                <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-3s">CONTINUAR</button>

            </div>

            <Footer />
        </div>
    )

}

export default PdfTokenInstructions