import React, { useState, useEffect, Fragment } from "react"
import { Link, useHistory } from "react-router-dom"
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import moment from 'moment'
import NumberFormat from 'react-number-format';
import {
    agregarInfo, statusData, formatoFechaOcr, formatoFechaCuatroDigitos,
    validarCampo, validarValoresFormulario, evento, encolarPeticionRenapo, encolarPeticionINE, generateZip,
    statusError, validarRedireccionamiento
} from '../../services/data'
import Info from '../../components/encuentra_info'
import Loader from "../../components/loader"
import InfoIne from '../../components/info_ine'
import { isIOS } from 'react-device-detect'
import Footer from '../../components/footer'
import CancelarFlujo from '../../components/cancelar_flujo'


let FormularioIneConsulta = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false)
    const [focusClave, setfocusClave] = useState(false);
    const [focusRegistro, setfocusRegistro] = useState(false)
    const [focusEmision, setfocusEmision] = useState(false)
    const [focusOCR, setfocusOCR] = useState(false)
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false)
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        nombreCompleto: '',
        fechaNacimiento: '',
        clave: '',
        fechaRegistro: '',
        numeroEmision: '',
        vigencia: '',
        curp: ''
    })
    const [inputsErrors, setInputsErrors] = useState({})
    const [uuid, setUuid] = useState('')
    const [sendForm, setSendForm] = useState(false)
    const [esVigente, setEsVigente] = useState(true)
    const [loading, setLoading] = useState(false)
    const [modeloIdentificacion, setModeloIdentificacion] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [continuar, setContinuar] = useState(false)
    const [showFooter, setShowFooter] = useState(true)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [uuidTransaccion, setUuidTransaccion] = useState('')

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }

        let uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) {
            setUuidTransaccion(uuidTransaccionLS)
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS) {
            if (ocrFront.nombres)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombreCompleto"]: ocrFront.nombreCompleto || '' }))
            if (ocrFront.claveElector)
                setInputsValue(inputsValue => ({ ...inputsValue, ["clave"]: (ocrFront.claveElector).trim() }))
            if (ocrFront.anioRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["fechaRegistro"]: (ocrFront.anioRegistro).trim() }))
            if (ocrFront.numeroRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["numeroEmision"]: (ocrFront.numeroRegistro).trim() }))
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }))
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }))
                }
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }))
            let vigenciaC = ''
            if (ocrFront.vigencia) {
                vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }))
            }

            setModeloIdentificacion('A')
        }

        const dataOCRBackLS = localStorage.getItem("ocrBack")

        if (dataOCRBackLS) {
            const ocrBack = JSON.parse(dataOCRBackLS)
            let vigenciaC = ''

            if (ocrBack.ineVigencia) {
                vigenciaC = ocrBack.ineVigencia
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    // setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }))
                }
            } else {
                if (ocrFront.vigencia) {
                    if (vigenciaC) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }))
                    } else {
                        let vi = ""
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/');
                        else
                            vi = ocrFront.vigencia;
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vi }))
                    }
                } else {
                    if (ocrFront.vigencia)
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }))
                }
            }
            let ocr = '';
            if (ocrBack.seccionMRZ)
                ocr = ocrBack.seccionMRZ.trim()
            if (ocrBack.ciudadanoMRZ)
                ocr = `${ocr}${ocrBack.ciudadanoMRZ.trim()}`
            setInputsValue(inputsValue => ({ ...inputsValue, ["ocr"]: ocr }))
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        if (continuar) {
            setLoading(false)
            setTimeout(() => {
                history.push('/identificacion_edit')
            }, 300);
        }
    }, [continuar])

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist()
        let validacion = null
        let value = event.target.value.trim().toUpperCase()
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength)
                event.target.value = value
            }
            validacion = validarCampo(value, event.target.name)
        } else {
            validacion = validarCampo(value, event.target.name)
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }))
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }))
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }))
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }))
        }
    };

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation()
        setSendForm(true);
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            let lengthString = inputsValue.ocr.length;
            let idCiudadano = inputsValue.ocr.substring(4, lengthString);
            setLoading(true);
            if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "false") === "true") {
                encolarPeticionRenapo(inputsValue.curp)
            }
            if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "false") === "true") {
                encolarPeticionINE({
                    ocr: inputsValue.ocr,
                    idCiudadano: idCiudadano,
                    claveElector: inputsValue.clave,
                    numeroEmision: inputsValue.numeroEmision,
                })
            }
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true)
            let infoFormulario = []
            let fechaActual = inputsValue.vigencia;
            agregarInfo(dataUser, { description: "nombreCompleto", value: inputsValue.nombreCompleto })
            infoFormulario.push({ description: "nombreCompleto", value: inputsValue.nombreCompleto })
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp });
            agregarInfo(dataUser, { description: "claveElector", value: inputsValue.clave });
            infoFormulario.push({ description: "claveElector", value: inputsValue.clave });
            agregarInfo(dataUser, { description: "fechaRegistro", value: inputsValue.fechaRegistro });
            infoFormulario.push({ description: "fechaRegistro", value: inputsValue.fechaRegistro });
            agregarInfo(dataUser, { description: "numeroEmision", value: inputsValue.numeroEmision });
            infoFormulario.push({ description: "numeroEmision", value: inputsValue.numeroEmision });
            agregarInfo(dataUser, { description: "ocr", value: inputsValue.ocr });
            infoFormulario.push({ description: "ocr", value: inputsValue.ocr });
            agregarInfo(dataUser, { description: "idCiudadano", value: idCiudadano });
            agregarInfo(dataUser, { description: "modeloIdentificacion", value: modeloIdentificacion });
            let today = new Date();
            let year = today.getFullYear();
            if (Number(fechaActual) >= year) {
                infoFormulario.push({ description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) })
                agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });
                sendData(infoFormulario)
                setContinuar(true)
            } else {
                setLoading(false)
                setEsVigente(false)
            }
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false)
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }))
            setSendForm(false)
        }
    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase()

    const terminarFlujo = async (event) => {
        if (event) event.preventDefault()
        let data = 'Cancelado';
        setLoading(true);
        const responseZip = await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("datos_personales_ocr", data, "cancelado")
        evento('Datos personales OCR', 'Click', { description: 'TERMINAR', status: data }, true)
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.push("/" + apiKey)
                setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
            }
        }
    }

    return (
        <Fragment>
            <form id="FormularioIneConsulta">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombreCompleto !== "") ? "is-focused" : ""), inputsErrors.nombreCompleto && 'error'].join(" ")}>
                        <label htmlFor="nombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="nombreCompleto" name="nombreCompleto" defaultValue={inputsValue.nombreCompleto} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                            setShowFooter(false)
                        }} onBlur={e => {
                            setShowFooter(true)
                            if (inputsValue.nombreCompleto === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.nombreCompleto && (
                            <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCompleto}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control none_border" id="fechaNacimiento" name="fechaNacimiento" value={inputsValue.fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                            setShowFooter(false)
                        }
                        } onBlur={e => {
                            setShowFooter(true)
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusClave || inputsValue.clave !== "") ? "is-focused" : ""), inputsErrors.clave && 'error'].join(" ")}>
                        <label htmlFor="clave" className="bmd-label-floating">Clave de elector:*Dato frontal</label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="clave" name="clave" maxLength="18" defaultValue={inputsValue.clave} onChange={handleChange} onFocus={e => {
                                setfocusClave(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.clave === "") {
                                    setfocusClave(false)
                                }
                            }} />
                            <span className={`span_info ${inputsErrors.clave ? 'red_border_bottom' : ''}`}>
                                <input type='button' className='form-control info' onClick={() => setShowInfo(true)} />
                            </span>
                        </div>
                        {inputsErrors.clave && (
                            <span id="ht-clave" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.clave}</span>
                        )}
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusRegistro || inputsValue.fechaRegistro !== "") ? "is-focused" : ""), inputsErrors.fechaRegistro && 'error'].join(" ")}>
                                <label htmlFor="fechaRegistro" className="bmd-label-floating">Año de registro:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="fechaRegistro" name="fechaRegistro" maxLength="4" value={inputsValue.fechaRegistro || ''} onChange={handleChange} onFocus={e => {
                                        setfocusRegistro(true)
                                        setShowFooter(false)
                                    }
                                    } onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.fechaRegistro === "") {
                                            setfocusRegistro(false)
                                        }
                                    }} />
                                    <span className={`span_info ${inputsErrors.fechaRegistro ? 'red_border_bottom' : ''}`}>
                                        <input type='button' className='form-control info' onClick={() => setShowInfo(true)} />
                                    </span>
                                </div>
                                {inputsErrors.fechaRegistro && (
                                    <span id="ht-registro" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaRegistro}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusEmision || inputsValue.numeroEmision !== "") ? "is-focused" : ""), inputsErrors.numeroEmision && 'error'].join(" ")}>
                                <label htmlFor="numeroEmision" className="bmd-label-floating">Número de emisión:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="numeroEmision" name="numeroEmision" value={inputsValue.numeroEmision || ''} maxLength="2" onChange={handleChange} onFocus={e => {
                                        setfocusEmision(true)
                                        setShowFooter(false)
                                    }
                                    } onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.numeroEmision === "") {
                                            setfocusEmision(false)
                                        }
                                    }} />
                                    <span className={`span_info ${inputsErrors.numeroEmision ? 'red_border_bottom' : ''}`}>
                                        <input type='button' className='form-control info' onClick={() => setShowInfo(true)} />
                                    </span>
                                </div>
                                {inputsErrors.numeroEmision && (
                                    <span id="ht-emision" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroEmision}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusOCR || inputsValue.ocr !== "") ? "is-focused" : ""), inputsErrors.ocr && 'error'].join(" ")}>
                        <label htmlFor="ocr" className="bmd-label-floating">OCR:<small> *Dato reverso</small></label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="ocr" name="ocr" maxLength="13" defaultValue={inputsValue.ocr} onChange={handleChange} onFocus={e => {
                                setfocusOCR(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.ocr === "") {
                                    setfocusOCR(false)
                                }
                            }} />
                            <span className={`span_info ${inputsErrors.ocr ? 'red_border_bottom' : ''}`}>
                                <input type='button' className='form-control info' onClick={() => setShowInfo(true)} />
                            </span>
                        </div>
                        {inputsErrors.ocr && (
                            <span id="ht-OCR" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.ocr}</span>
                        )}

                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                        <input type="text" className="form-control uppercase none_border" id="curp" name="curp" defaultValue={inputsValue.curp} maxLength="19" onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                            setFocusCurp(true)
                            setShowFooter(false)
                        }} onBlur={e => {
                            setShowFooter(true)
                            if (inputsValue.curp === "") {
                                setFocusCurp(false)
                            }
                        }} />
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigencia !== "") ? "is-focused" : ""), inputsErrors.vigencia && 'error'].join(" ")}>
                                <label htmlFor="vigencia" className="bmd-label-floating">Vencimiento identificación:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="vigencia" name="vigencia" maxLength="4" defaultValue={inputsValue.vigencia} onChange={handleChange} onFocus={e => {
                                        setFocusVigencia(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.vigencia === "") {
                                            setFocusVigencia(false)
                                        }
                                    }} />
                                    <span className={`span_info ${inputsErrors.vigencia ? 'red_border_bottom' : ''}`}>
                                        <input type='button' className='form-control info' onClick={() => setShowInfo(true)} />
                                    </span>
                                </div>
                                {inputsErrors.vigencia && (
                                    <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigencia}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                    <Info />
                </div>
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)} disabled={sendForm ? true : false}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color">CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
            {(!esVigente) && (
                <div className="err_finale  animated slideInUp">
                    <div className="center_checks">
                        <h5>Identificación no valida </h5>
                        <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                        </p>
                        <br />
                        <div className="action_buttons noscroll_screen">
                            <Link to={{
                                pathname: "/identificacion",
                                state: { passport: esPassport }
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={e => evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)}>CAPTURAR NUEVAMENTE</Link>
                            <button className="btn btn-secondary forcewidth100" onClick={event => terminarFlujo(event)}>TERMINAR</button>
                        </div>
                    </div>
                </div>)}
            {loading && <Loader />}
            {showInfo && <InfoIne close={() => setShowInfo(false)} />}
            {showFooter && <Footer />}
            {showCancelScreen  && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'}/>}
        </Fragment>
    )
}

export default FormularioIneConsulta