import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const HeaderNA = (props) => {
    const location = useLocation();
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [pass, setPass] = useState(false)
    const [pathname, setpathname] = useState('');

    /* Refs */
    const divRef = useRef(null)
    const imgRef = useRef(null)

    useEffect(() => {
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(JSON.parse(dataOtorganteLS))
        }
        if(location.state){
            if (location.state.passport) {
                setPass(true)
                //console.log("passaprote true");
            }else{
                setPass(false)
                //console.log("passaprote false");
            }
        }
        let host = localStorage.getItem("apikeyOtorgante");
        setpathname(host)
    }, []);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const imageOnLoad = () => {
        if (imgRef.current) {
            const valorPadding = Math.round((window.innerWidth - imgRef.current.width) / 2)
            if(divRef.current){
                divRef.current.style.paddingLeft = `${valorPadding}px`
                divRef.current.style.paddingRight = `${valorPadding}px`
                divRef.current.removeAttribute('hidden')
            }
        }
    }

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") !== "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" ref={imgRef} onLoad={imageOnLoad}/>
                {/* <div ref={divRef} className='div-version' hidden>
                    <p>ver.2.3.11</p>
                </div> */}
                {
                    (pathname === "i2MopevgEp2HSmOppDOR3MHUzT4SEZOi") ? 
                      <a className="help_btn"><img src={process.env.PUBLIC_URL + '/images/btn_circle_help_c2all.svg'} alt="" onClick={e => props.show()}/></a>
                        :
                        ""
                }
            </div>
        </Fragment>
    );
}

export default HeaderNA;