import React from 'react';

const OverlayCargandoCamara = () => {
    return (
        <>
            <div className="module_gradient_overlay_loader ">
                <div className="loader-38"></div>
            </div>
        </>
    );
}

export default OverlayCargandoCamara;
