export const generateHeaders = (object, type, newHeaders = []) => {
    let headers = {};

    headers = {
        'transaccion': object.uuidTransaccion,
        'cliente': object.uuidUser,
        'apikey': object.apikey
    };

    switch (type) {
        case 'json':
            headers['Content-Type'] = 'application/json';
            break;
        case 'form-data':
            headers['Content-Type'] = 'multipart/form-data';
            break;
        case 'form-urlencoded':
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            break;
        default:
            break;
    }

    newHeaders.forEach(element => {
        if (element.value) {
            headers[element.key] = element.value;
        }
    });

    return headers;
}