/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import HeaderNA from "../components/header"
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { hayExcepcion, initStatus, evento, setCancelarINE, setCancelarRENAPO } from '../services/data'
import Ayuda from '../pages/ayuda'

const Inicio = () => {
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [isOpenByPivot, setIsOpenByPivot] = useState(false)
    const [nombreOtorgante, setNombreOtorgante] = useState(null)
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const history = useHistory()
    const [showHelp, setShowHelp] = useState(false)
    const servicio = process.env.REACT_APP_SERVICE

    useEffect(() => {

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            const data = JSON.parse(dataOtorganteLS)
            setDataOtorgante(data)
        }

        let uuidTrx = localStorage.getItem("uuidTrx")
        uuidTrx && setUuidTransaccion(uuidTrx)

        if (window.opener) {
            setIsOpenByPivot(true);
        }

    }, [])


    useEffect(() => {
        configStyle(dataOtorgante)
        if (dataOtorgante.length > 0) {
            setNombreOtorgante(obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""))
        }
    }, [dataOtorgante])

    useEffect(() => {
        if (isOpenByPivot && nombreOtorgante) {
            initStatus(nombreOtorgante)
        }
    }, [isOpenByPivot, nombreOtorgante])

    const startFlow = () => {
        setCancelarINE()
        setCancelarRENAPO()
        evento('Inicio', 'Click', { description: 'EMPEZAR' }, true)
        if (!isOpenByPivot) {
            initStatus(nombreOtorgante);
        }
        let nextStep = (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_1', "true") === "true") ? "/terminos" : "/preparacion_identificacion"
        //let nextStep = "/liveness"
        setTimeout(() => {
            history.push(nextStep);
        }, 200);
    }

    return (
        <>
            <div className="main_gradient">
                <div className="container_grid">
                    <HeaderNA show={() => setShowHelp(true)} />
                    <div>
                        <div className="main_text_container custom_padding">
                            <h1 className="animate__animated animate__fadeIn animate__delay-1s" style={{ marginBottom: '0' }}>Inicia tu solicitud</h1>
                            <div className="animate__animated animate__fadeIn animate__delay-2s">Para validar tu identidad necesitas lo siguiente:
                                {/* <div className="animate__animated animate__fadeIn animate__delay-2s">Para autorizar la petición de {(obtenerValorConfig(dataOtorgante, 'TITULO_DEL_CONSENTIMIENTO_2', "reporte de crédito"))}, necesitas lo siguiente: */}
                                <br />
                                <br />
                                <ul className="nomrgnnopdng animate__animated animate__fadeIn animate__delay-2s">
                                    <li><b>Una identificación oficial vigente:</b>
                                        {(hayExcepcion()) ?
                                            <ul className="nomrgnnopdng">
                                                <li>DNI</li>
                                            </ul>
                                            :
                                            <ul className="sub_list">
                                                <li className="spaceLi"><em>INE / IFE</em></li>
                                                <li className="spaceLi"><em>Pasaporte Mexicano</em></li>
                                                <li className="spaceLi"><em>Documento migratorio FMM</em></li>
                                            </ul>
                                        }
                                    </li>
                                    <li>Dar <b>acceso a la cámara</b></li>
                                    <li>Una <b>buena conexión</b>  a internet</li>
                                    <br />
                                    <li><b>Evita lo siguiente:</b></li>
                                </ul>
                                <div className="row id_type_bx">
                                    <div className="col-6">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <h6>Cara en ID cubierta</h6>
                                            <div className="maskface id_cntr"></div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <h6>Audífonos conectados</h6>
                                            <div className="maskface headphones"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {servicio !== 'prod_' &&
                            <div className='div-text-center'>
                                <p className='animate__animated'>
                                    Folio: <br /> <b>{uuidTransaccion.split('-')[0]}</b>
                                </p>
                            </div>
                        }
                        <div className="action_buttons">
                            <button onClick={startFlow} className="btn btn-raised btn-primary forcewidth100 main_bg_color animated fadeIn delay-3s">EMPEZAR</button>
                        </div>
                    </div>
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>
    )
}

export default Inicio