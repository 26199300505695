import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
//import { Dicio } from '@dicio/pivot-react'

const Pivote = () => {
    const { apikey } = useParams();
    const [status, setStatus] = useState({})
    const [errores, setErrores] = useState('')
    const [data, setData] = useState({})

    return (
        <div>
            <div className="main_gradient">
                <Header ruta={apikey} />
                <br />
                <div className="action_buttons">
                    {/* <Dicio
                        auth={{
                            id: "eb97bcad-b465-49f2-b5e0-d2ed1751f0b1",
                            key: "i2MopevgEp2HSmOppDOR3MHUzT4SEZOi"
                        }}
                        text="Iniciar proceso"
                        variant="only_logo"
                        debug={true}
                        mode="dev"
                        status={(evento) => { 
                            setStatus(evento) 
                        }}
                        errors={(error => { 
                            console.log("error", error); 
                            setErrores(JSON.stringify(error)) 
                        })}
                        finish={(data) => { 
                            console.log("finish", data); 
                            document.getElementById("step").innerHTML=data.step
                            document.getElementById("result").innerHTML=data.uuid
                            document.getElementById("data").innerHTML=data.result
                            setData(JSON.stringify(data)) 
                            }}>
                    </Dicio> */}

                </div>
                <div className="main_text_container">
                    <table>
                        <thead>
                            <tr>
                                <th>Step</th>
                                <th>Status</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (status !== undefined) ?
                                    <tr>
                                        <td id="step">{status.step}</td>
                                        <td id="result">{status.result}</td>
                                        <td id="data">{JSON.stringify(status.data)}</td>
                                    </tr>
                                    :
                                    ""
                            }
                        </tbody>
                    </table>
                    {
                        (errores !== undefined) ?
                            <div>
                                <h3>Errores</h3>
                                <p>{errores}</p>
                            </div>
                            :
                            null
                    }

                </div>

                <Footer />
            </div>
        </div>
    )
}

export default Pivote