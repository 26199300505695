import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, /*obtenerValorConfig*/ } from '../../services/configStyle'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
    hayExcepcion, agregarInfo, statusData, formatoFechaOcr, formatoFechaCuatroDigitos,
    validarCampo, validarValoresFormulario, evento, generateZip, statusError, validarRedireccionamiento
} from '../../services/data'
import Loader from '../../components/loader'
import { isIOS } from 'react-device-detect'
import Footer from '../../components/footer'
import CancelarFlujo from '../../components/cancelar_flujo'

let FormularioIne = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false);
    const [focusVigencia, setFocusVigencia] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esIne, setEsIne] = useState(true);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        nombreCompleto: '',
        fechaNacimiento: '',
        vigencia: '',
        curp: ''
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esVigente, setEsVigente] = useState(true)
    const [fechaNacimiento, setfechaNacimiento] = useState("");
    const [sendForm, setSendForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFooter, setShowFooter] = useState(true)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [uuidTransaccion, setUuidTransaccion] = useState('')

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }

        let uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) {
            setUuidTransaccion(uuidTransaccionLS)
        }

        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.nombres)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombreCompleto"]: ocrFront.nombreCompleto || '' }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
                setfechaNacimiento(ocrFront.fechaNacimiento)
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            let vigenciaC = ''
            if (ocrFront.vigencia)
                vigenciaC = "20" + ocrFront.vigencia.substr(-2);
            setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
        }

        if (localStorage.getItem("apikeyOtorgante") === "50e6a1b5-fc07-459d-a78a-de4a3cad810b") {
            setCLaboral(false)
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack");

        if (dataOCRBackLS != null) {
            setDataOCRBack(JSON.parse(dataOCRBackLS));
            const ocrBack = JSON.parse(dataOCRBackLS);
            let vigenciaC = '';

            if (ocrFront.ineVigencia) {
                if (ocrBack.vigencia) {
                    vigenciaC = ocrBack.ineVigencia;
                    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                    if (moment(vigenciaC, formatosP, true).isValid()) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                    }
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                } else {
                    if (ocrFront.vigencia) {
                        if (vigenciaC) {
                            setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                        } else {
                            let vi = ""
                            if (ocrFront.vigencia.includes(' '))
                                vi = ocrFront.vigencia.replaceAll(' ', '/');
                            else
                                vi = ocrFront.vigencia;
                            setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vi }));
                        }
                    } else {
                        if (ocrFront.vigencia)
                            vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                    }
                }
            }
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = null;
        let value = event.target.value.trim().toUpperCase();
        if (event.target.name === "fechaNacimiento") {
            setfechaNacimiento(value)
        }
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        } else {
            validacion = validarCampo(value, event.target.name);
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    useEffect(() => {
        // console.log("inputsValueM", inputsValue);
    }, [inputsValue]);

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        setSendForm(true);
        //console.log("valores",valores);
        let continuar = validarValoresFormulario(inputsValue);

        if (continuar.correcto) {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true);
            /* if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "true") === "true") {
                enviarRenapo(inputsValue.curp).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log("Error RENAPO: " + error);
                });
            }

            if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "true") === "true" && esIne) {
                enviarIne(inputsValue.cic, inputsValue.clave, inputsValue.numeroEmision).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log("Error INE: " + error);
                });
            } */

            let infoFormulario = []
            let fechaActual = inputsValue.vigencia
            agregarInfo(dataUser, { description: "nombreCompleto", value: inputsValue.nombreCompleto })
            infoFormulario.push({ description: "nombreCompleto", value: inputsValue.nombreCompleto })
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp })
            let today = new Date();
            let year = today.getFullYear();
            if (Number(fechaActual) >= year) {
                infoFormulario.push({ description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) })
                agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });
                fechaActual = "20" + inputsValue.vigencia.substr(-2) + "/12/31";
                let fechaV = new Date(fechaActual);
                if (fechaV >= Date.now()) {
                    console.log("Vigencia válida");
                    sendData(infoFormulario);
                    setTimeout(() => {
                        if (hayExcepcion()) {
                            history.push('/clausula');
                        } else {
                            history.push('/identificacion_edit')
                        }
                    }, 300);
                } else {
                    console.log("Vigencia invalida");
                    setEsVigente(false)
                }
            } else {
                console.log("Vigencia invalida");
                setEsVigente(false)
            }
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }

    const statusSE = () => {
        evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true);
    }

    const terminarFlujo = async (event) => {
        if (event) event.preventDefault()
        let data = 'Cancelado'
        setLoading(true)
        const responseZip = await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("datos_personales_ocr", data, "cancelado")
        evento('Datos personales OCR', 'Click', { description: 'TERMINAR', status: data }, true)
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.push("/" + apiKey)
                setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
            }
        }
    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase()

    return (
        <>
            <form id="FormularioIne">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombreCompleto !== "") ? "is-focused" : ""), inputsErrors.nombreCompleto && 'error'].join(" ")}>
                        <label htmlFor="nombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="nombreCompleto" name="nombreCompleto" defaultValue={inputsValue.nombreCompleto} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                            setShowFooter(false)
                        }} onBlur={e => {
                            setShowFooter(true)
                            if (inputsValue.nombreCompleto === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.nombreCompleto && (
                            <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCompleto}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control" id="fechaNacimiento" name="fechaNacimiento" defaultValue={inputsValue.fechaNacimiento} value={fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                            setShowFooter(false)
                        }
                        } onBlur={e => {
                            setShowFooter(true)
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigencia !== "") ? "is-focused" : ""), inputsErrors.vigencia && 'error'].join(" ")}>
                        <label htmlFor="vigencia" className="bmd-label-floating">Vencimiento identificación:</label>
                        <input type="text" className="form-control" id="vigencia" name="vigencia" maxLength="4" defaultValue={inputsValue.vigencia} onChange={handleChange} onFocus={e => {
                            setFocusVigencia(true)
                            setShowFooter(false)
                        }} onBlur={e => {
                            setShowFooter(true)
                            if (inputsValue.vigencia === "") {
                                setFocusVigencia(false)
                            }
                        }} />
                        {inputsErrors.vigencia && (
                            <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigencia}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                        <input type="text" className="form-control uppercase" id="curp" name="curp" defaultValue={inputsValue.curp} maxLength="19" onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                            setFocusCurp(true)
                            setShowFooter(false)
                        }} onBlur={e => {
                            setShowFooter(true)
                            if (inputsValue.curp === "") {
                                setFocusCurp(false)
                            }
                        }} />
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>
                </div>
                {(!esVigente) && (
                    <div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Identificación no valida </h5>
                            <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <Link to={{
                                    pathname: "/identificacion",
                                    state: { passport: esPassport }
                                }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CAPTURAR NUEVAMENTE</Link>
                                <button className="btn btn-secondary forcewidth100" onClick={event => terminarFlujo(event)}>TERMINAR</button>
                            </div>
                        </div>
                    </div>)}
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)} disabled={sendForm}  >MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={statusSE}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
            {loading && <Loader />}
            {showFooter && <Footer />}
            {showCancelScreen  && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'}/>}
        </>
    )
}

export default FormularioIne