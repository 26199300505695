import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import { obtenerValorConfig, obtenerValorUser } from '../services/configStyle'
import { configStyle } from '../services/configStyle'
import Footer from "../components/footer";
import { obtenerMaps, actualizarScore, obtenerCP } from '../services/api';
import { status, statusData, agregarInfo, evento, validarCampo, validarValoresFormulario, validarParametroOtorgante, getNextStepIdentification } from '../services/data';
import Ayuda from './ayuda';

let IdentificacionEdit = (props) => {
    const history = useHistory();
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [codigoPostal, setCodigoPostal] = useState("")
    const [focusCodigoPostal, setFocusCodigoPostal] = useState(false)
    const [focusPais, setFocusPais] = useState(false)
    const [focusEstado, setFocusEstado] = useState(false)
    const [focusDelegacion, setFocusDelegacion] = useState(false)
    const [focusColonia, setFocusColonia] = useState(false)
    const [focusCalle, setFocusCalle] = useState(false)
    const [focusNumeroExterior, setFocusNumeroExterior] = useState(false)
    //const [Gps, setGps] = useState('[19.4323007,-99.2708557]')
    const [inputActive, setInputActive] = useState(false)
    const [databack, setDataback] = useState(false)
    const [cambioCP, setCambioCP] = useState(false)
    const [otro, setOtro] = useState(false)
    const [optionsSelect, setoptionsSelect] = useState([]);
    // const [errorUbicacion, setErrorUbicacion] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [focusNumeroInterior, setFocusNumeroInterior] = useState(false);
    const [inputsValue, setInputsValue] = useState({
        codigoPostal: '',
        pais: '',
        estado: '',
        delegacion: '',
        colonia: '',
        calle: '',
        numeroExterior: '',
        numeroInterior: '',
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [continuar, setContinuar] = useState(false)
    const [showFooter, setShowFooter] = useState(true)

    useEffect(() => {
        let dataOtorganteLS = "", dataUserLS = "", dataOCRFrontLS = "";
        dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        dataOCRFrontLS = localStorage.getItem("ocrFront")
        if (dataOCRFrontLS != null) {
            const ocrFront = JSON.parse(dataOCRFrontLS)
            setInputsValue(inputsValue => ({ ...inputsValue, ['codigoPostal']: ocrFront.cp || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['pais']: 'México' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['calle']: ocrFront.calle || '' }));
            let numExt = (ocrFront.numero) ? ocrFront.numero.trim() : "";
            setInputsValue(inputsValue => ({ ...inputsValue, ['numeroExterior']: numExt || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['estado']: ocrFront.estado || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['delegacion']: ocrFront.municipio || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['colonia']: ocrFront.colonia || '' }));
            setDataback(true)
        }
        status("datos_direccion_ocr_page", "Datos de direccion OCR")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        let tipo = '';
        let informacion = {};
        if (inputsValue.codigoPostal && inputsValue.codigoPostal.length === 5 && !databack && cambioCP) {
            setCambioCP(false)
            obtenerCP(codigoPostal).then(({ status, data: { payload } }) => {
                //console.log("data:", response);
                if (status === 200) {
                    informacion.status = status;
                    informacion.payload = payload;
                    evento('Obtener Código Postal', 'Success', informacion, true);
                    //setCodigoPostal(codigoPostal);
                    setInputsValue(inputsValue => ({ ...inputsValue, ['codigoPostal']: inputsValue.codigoPostal }));
                    if (payload.address.length > 0) {
                        let dir = payload.address[0];
                        let delegacionW = dir.municipio
                        let estadoW = dir.estado
                        let coloniaW = dir.asentamiento
                        let arreglo = [];
                        let tam = (payload.address).length
                        if (tam > 1) {
                            for (let i = 0; i < tam; i++) {
                                let colonia = payload.address[i].asentamiento
                                arreglo.push(colonia);
                            }
                        } else {
                            let colonia1 = dir.asentamiento
                            arreglo.push(colonia1);
                        }
                        setoptionsSelect(arreglo)
                        setInputsValue(inputsValue => ({ ...inputsValue, ['colonia']: coloniaW }));
                        setInputsValue(inputsValue => ({ ...inputsValue, ['delegacion']: delegacionW }));
                        setInputsValue(inputsValue => ({ ...inputsValue, ['estado']: estadoW }));
                        setInputsValue(inputsValue => ({ ...inputsValue, ['pais']: 'México' }));
                    } else {
                        setInputsValue(inputsValue => ({ ...inputsValue, ['colonia']: '' }));
                        setInputsValue(inputsValue => ({ ...inputsValue, ['delegacion']: '' }));
                        setInputsValue(inputsValue => ({ ...inputsValue, ['estado']: '' }));
                        setInputsValue(inputsValue => ({ ...inputsValue, ['pais']: '' }));
                    }
                }
                //setLoading(false)
            }).catch((error) => {
                //console.log("algun error");
                if (error.response) {
                    let { data: { message }, status } = error.response
                    informacion.mensaje = message;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Obtener Código Postal', tipo, informacion, false);
                if (inputActive) {
                    //console.log("inputActive algun error",inputActive);
                }
            })
            setTimeout(() => {
                setInputsValue(inputsValue => ({ ...inputsValue, ['codigoPostal']: inputsValue.codigoPostal }));
            }, 1000);
        }
    }, [inputsValue.codigoPostal])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    const actualizarDatos = (event) => {
        // if (Gps !== '[19.4323007,-99.2708557]' && calle.trim()) {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        let tipo = '';
        let informacion = {};
        if (continuar) {
            evento('Datos de direccion OCR', 'Click', { description: 'GUARDAR', status: 'OK' }, true);
            agregarInfo(dataUser, { description: "codigoPostal", value: inputsValue.codigoPostal });
            agregarInfo(dataUser, { description: "pais", value: inputsValue.pais });
            agregarInfo(dataUser, { description: "estado", value: inputsValue.estado });
            agregarInfo(dataUser, { description: "delegacion", value: inputsValue.delegacion });
            agregarInfo(dataUser, { description: "calle", value: inputsValue.calle });
            agregarInfo(dataUser, { description: "numeroExterior", value: inputsValue.numeroExterior });
            agregarInfo(dataUser, { description: "calleCompleta", value: inputsValue.calle + " " + inputsValue.numeroExterior });
            let infoFormulario = []
            infoFormulario.push({ description: "codigoPostal", value: inputsValue.codigoPostal })
            infoFormulario.push({ description: "pais", value: inputsValue.pais })
            infoFormulario.push({ description: "estado", value: inputsValue.estado })
            infoFormulario.push({ description: "delegacion", value: inputsValue.delegacion })
            if (inputsValue.otraColonia) {
                infoFormulario.push({ description: "colonia", value: inputsValue.otraColonia })
                agregarInfo(dataUser, { description: "colonia", value: inputsValue.otraColonia });
            } else {
                infoFormulario.push({ description: "colonia", value: inputsValue.colonia });
                agregarInfo(dataUser, { description: "colonia", value: inputsValue.colonia });
            }
            infoFormulario.push({ description: "calle", value: inputsValue.calle });
            infoFormulario.push({ description: "numeroExterior", value: inputsValue.numeroExterior });
            infoFormulario.push({ description: "calleCompleta", value: inputsValue.calle + " " + inputsValue.numeroExterior });
            if (inputsValue.numeroInterior) {
                agregarInfo(dataUser, { description: "numeroInterior", value: inputsValue.numeroInterior });
                infoFormulario.push({ description: "numeroInterior", value: inputsValue.numeroInterior });
            }
            // infoFormulario.push({ description: "gps", value: Gps })
            document.getElementById("form-ocr").reset();
            //actualizarScore
            let uuid = localStorage.getItem("uuidUser");
            let empresa = (obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""));
            let coloniaParam = inputsValue.otraColonia ? inputsValue.otraColonia : inputsValue.colonia;
            // actualizarScore(uuid, calle1, colonia1, cp, estado1, municipio, numeroExt, Gps, empresa).then((response) => {
            actualizarScore(uuid, inputsValue.calle, coloniaParam, inputsValue.codigoPostal, inputsValue.estado, inputsValue.delegacion, inputsValue.numeroExterior, empresa).then((response) => {
                if (response.status === 200) {
                    //console.log(response.data);
                    informacion.status = response.status;
                    informacion.payload = response.data.payload;
                    evento('Actualizar Score', 'Success', informacion, true);
                    sendData(infoFormulario);
                    setTimeout(() => {
                        if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                            history.push('/clausula');
                        } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                            history.push("/autorizacion_solicitud");
                        } else {
                            /*
                            if (uuidOtorgante === "iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt" || uuidOtorgante === "77b6a48f-eaf8-4b18-8808-374eaa1a3f2f") {
                                setTimeout(() => {
                                    history.push('/finalizado');
                                }, 200);
                            } else {
                                history.push("/video_token");
                            }
                            */
                            let nextStep = getNextStepIdentification(dataOtorgante)
                            history.push(nextStep);
                        }
                    }, 200);
                }
            }).catch((error) => {
                //console.log("algun error actualizarScore");
                if (error.response) {
                    let { data: { message }, status } = error.response
                    informacion.mensaje = message;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Actualizar Score', tipo, informacion, false);
                sendData(infoFormulario);
                setTimeout(() => {
                    if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                        history.push('/clausula');
                    } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                        history.push("/autorizacion_solicitud");
                    } else {
                        // SE COMENTÓ PARA IMPLEMENTAR VALIDACIÓN
                        //history.push("/video_token");
                        let nextStep = getNextStepIdentification(dataOtorgante)
                        history.push(nextStep)
                    }
                }, 200);
            })
        } else {
            // if (Gps === '[19.4323007,-99.2708557]') {
            //     setErrorUbicacion(true);
            // }
            evento('Datos de direccion OCR', 'Click', { description: 'GUARDAR', status: 'Error' }, true);
        }
    }

    const verificarCP = (value) => {
        let name = 'codigoPostal';
        let validacion = validarCampo(value, name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [name]: '' }));
            setCodigoPostal(value)
            setDataback(false)
            setCambioCP(true)
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const revisarColonia = (value) => {
        let name = 'colonia';
        let validacion = validarCampo(value, name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [name]: '' }));
            console.log('VALUE', value)
            if (value === "Otro") {
                setOtro(true);
                setInputsValue(inputsValue => ({ ...inputsValue, ['otraColonia']: '' }));
            } else {
                setOtro(false);
                setInputsValue(inputsValue => ({ ...inputsValue, ...delete inputsValue.otraColonia }))
                setInputsErrors(inputsErrors => ({ ...inputsErrors, ...delete inputsErrors.otraColonia }))
            }
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }
    }

    const sendData = (infoFormulario) => {
        let jsonObj = {};
        jsonObj.infoDireccion = infoFormulario;
        statusData("datos_direccion_ocr", jsonObj)
    }

    // const geoLocalizacion = () => {
    //     let output = document.getElementById("out");


    //     if (!navigator.geolocation) {
    //         output.innerHTML = "<p>Su navegador no soporta la geolocalización</p>";
    //         return;
    //     }

    //     function success(position) {
    //         let latitude = position.coords.latitude;
    //         let longitude = position.coords.longitude;
    //         console.log("[" + latitude + "," + longitude + "]");
    //         output.innerHTML = " ";
    //         setGps("[" + latitude + "," + longitude + "]");
    //         setErrorUbicacion(false);
    //     };

    //     function error() {
    //         output.innerHTML = "No se puede encontrar su ubicación";
    //     };

    //     output.innerHTML = "<p>Localizando…</p>";

    //     navigator.geolocation.getCurrentPosition(success, error);

    //     let element = document.getElementById("check_switch");
    //     element.setAttribute("disabled", "disabled");
    // }

    const handleChange = (event) => {
        event.persist();
        let validacion = validarCampo(event.target.value, event.target.name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }
    };

    useEffect(() => {
        let validacion = validarValoresFormulario(inputsValue);
        if (validacion.correcto) {
            setContinuar(true);
            setInputsErrors(validacion.errores);
        } else {
            setContinuar(false);
            setInputsErrors(validacion.errores);
        }
    }, [inputsValue])

    return (
        <Fragment>
            <div className="main_gradient">
                <Header ruta="identificacion_edit" show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animate__animated">Verifica y completa tu dirección actual</h1>
                    <p className="animate__animated">Revisa que tu dirección sea correcta, puedes <b>editarla</b> o <b>corregirla</b>.</p>
                </div>

                <div className="ocr_data_display">
                    <form id="form-ocr">
                        <div className="row_ext">
                            <div className="row">
                                <div className="col-6">
                                    <div className={["form-group", "bmd-form-group", "col-50", ((focusCodigoPostal || inputsValue.codigoPostal !== "") ? "is-focused" : ""), inputsErrors.codigoPostal && 'error'].join(" ")}>
                                        <label htmlFor="codigoPostal" className="bmd-label-floating">Código Postal:</label>
                                        <input type="text" max="99999" className="form-control none_border" id="codigoPostal" name="codigoPostal" defaultValue={inputsValue.codigoPostal} onChange={e => verificarCP(e.target.value)} onFocus={e => {
                                            setFocusCodigoPostal(true)
                                            setInputActive(true)
                                            setShowFooter(false)
                                        }} onBlur={e => {
                                            setShowFooter(true)
                                            if (inputsValue.codigoPostal === '') {
                                                setFocusCodigoPostal(false)
                                            }
                                            setInputActive(false)
                                        }}
                                            maxLength="5" onInput={e => maxLengthCheck(e)} />
                                        {inputsErrors.codigoPostal && (
                                            <span id="ht-codigoPostal" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.codigoPostal}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={["form-group", "bmd-form-group", ((focusPais || inputsValue.pais !== "") ? "is-focused" : ""), inputsErrors.pais && 'error'].join(" ")}>
                            <label htmlFor="pais" className="bmd-label-floating">País:</label>
                            <input type="text" className="form-control none_border" id="pais" name="pais" defaultValue={inputsValue.pais} onChange={handleChange} onFocus={e => {
                                setFocusPais(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.pais === '') {
                                    setFocusPais(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.pais && (
                                <span id="ht-pais" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.pais}</span>
                            )}
                        </div>
                        <div className={["form-group", "bmd-form-group", ((focusEstado || inputsValue.estado !== "") ? "is-focused" : ""), inputsErrors.estado && 'error'].join(" ")}>
                            <label htmlFor="estado" className="bmd-label-floating">Estado / Provincia / Región:</label>
                            <input type="text" className="form-control none_border" id="estado" name="estado" defaultValue={inputsValue.estado} onChange={handleChange} onFocus={e => {
                                setFocusEstado(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.estado === '') {
                                    setFocusEstado(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.estado && (
                                <span id="ht-codigoPostal" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.estado}</span>
                            )}
                        </div>
                        <div className={["form-group", "bmd-form-group", ((focusDelegacion || inputsValue.delegacion !== "") ? "is-focused" : ""), inputsErrors.delegacion && 'error'].join(" ")}>
                            <label htmlFor="delegacion" className="bmd-label-floating">Delegación / Municipio:*</label>
                            <input type="text" className="form-control none_border" id="delegacion" name="delegacion" defaultValue={inputsValue.delegacion} onChange={handleChange} onFocus={e => {
                                setFocusDelegacion(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.delegacion === '') {
                                    setFocusDelegacion(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.delegacion && (
                                <span id="ht-delegacion" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.delegacion}</span>
                            )}
                        </div>
                        {(databack) ?
                            <div className={["form-group", "bmd-form-group", ((focusColonia || inputsValue.colonia !== "") ? "is-focused" : ""), inputsErrors.colonia && 'error'].join(" ")}>
                                <label htmlFor="colonia" className="bmd-label-floating">Colonia / Asentamiento:</label>
                                <input type="text" className="form-control none_border" id="colonia" name="colonia" defaultValue={inputsValue.colonia} onChange={handleChange} onFocus={e => {
                                    setFocusColonia(true)
                                    setInputActive(true)
                                    setShowFooter(false)
                                }} onBlur={e => {
                                    setShowFooter(true)
                                    if (inputsValue.colonia === '') {
                                        setFocusColonia(false)
                                    }
                                    setInputActive(false)
                                }} />
                                {inputsErrors.colonia && (
                                    <span id="ht-colonia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.colonia}</span>
                                )}
                            </div>
                            :
                            <div className={["form-group", "bmd-form-group", "is-focused", inputsErrors.colonia && 'error'].join(" ")}>
                                <label htmlFor="colonia" className="bmd-label-floating">Colonia / Asentamiento:</label>
                                <select className="custom-select" id="colonia" name="colonia" onChange={e => revisarColonia(e.target.value)} value={inputsValue.colonia}>
                                    <option value="" disabled>Seleccionar:</option>
                                    {
                                        optionsSelect.map(
                                            (objeto, index) => (
                                                <option key={`${objeto}`} value={`${objeto}`}>{objeto}</option>
                                            )
                                        )
                                    }
                                    <option value="Otro">Otro</option>
                                </select>
                                {inputsErrors.colonia && (
                                    <span id="ht-colonia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.colonia}</span>
                                )}
                            </div>
                        }
                        {(otro) &&
                            <div className={["form-group", "bmd-form-group", ((focusColonia || inputsValue.otraColonia !== "") ? "is-focused" : ""), inputsErrors.otraColonia && 'error'].join(" ")}>
                                <label htmlFor="otraColonia" className="bmd-label-floating">Colonia / Asentamiento:</label>
                                <input type="text" className="form-control none_border" id="otraColonia" name="otraColonia" defaultValue={inputsValue.otraColonia} onChange={handleChange} onFocus={e => {
                                    setFocusColonia(true)
                                    setInputActive(true)
                                    setShowFooter(false)
                                }} onBlur={e => {
                                    setShowFooter(true)
                                    if (inputsValue.colonia === '') {
                                        setFocusColonia(false)
                                    }
                                    setInputActive(false)
                                }} />
                                {inputsErrors.otraColonia && (
                                    <span id="ht-otraColonia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.otraColonia}</span>
                                )}
                            </div>}
                        <div className={["form-group", "bmd-form-group", ((focusCalle || inputsValue.calle) ? "is-focused" : ""), inputsErrors.calle && 'error'].join(" ")}>
                            <label htmlFor="calle" className="bmd-label-floating">Calle:</label>
                            <input type="text" className="form-control none_border" id="calle" name="calle" defaultValue={inputsValue.calle} onChange={handleChange} onFocus={e => {
                                setFocusCalle(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.calle === '') {
                                    setFocusCalle(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.calle && (
                                <span id="ht-calle" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.calle}</span>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroExterior || inputsValue.numeroExterior !== "") ? "is-focused" : ""), inputsErrors.numeroExterior && 'error'].join(" ")}>
                                    <label htmlFor="numeroExterior" className="bmd-label-floating">Número exterior:</label>
                                    <input type="text" className="form-control none_border" id="numeroExterior" name="numeroExterior" defaultValue={inputsValue.numeroExterior} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroExterior(true)
                                        setInputActive(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.numeroExterior === '') {
                                            setFocusNumeroExterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.numeroExterior && (
                                        <span id="ht-numeroExterior" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroExterior}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.numeroInterior) ? "is-focused" : ""), inputsErrors.numeroInterior && 'error'].join(" ")}>
                                    <label htmlFor="numeroInterior" className="bmd-label-floating">Número interior:</label>
                                    <input type="text" className="form-control none_border" id="numeroInterior" name="numeroInterior" defaultValue={inputsValue.numeroInterior} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroInterior(true)
                                        setInputActive(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.numeroInterior === '') {
                                            setFocusNumeroInterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.numeroInterior && (
                                        <span id="ht-numeroInterior" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroInterior}</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="break_float"></div>
                        <br />
                        {/* <table className="ubicacionGPS">
                            <tbody>
                                <tr>
                                    <th><div className="location_icon"></div></th>
                                    <th>Validar ubicación<br></br><small>Validar via GPS ahora</small></th>
                                    <th>
                                        <div className="checkboxswitch">
                                            <label htmlFor="check_switch" className="switchDir" >
                                                <input id="check_switch" type="checkbox" onChange={e => geoLocalizacion()} />
                                                <span className="sliderDir round"></span>
                                            </label>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table> */}
                        <span id="ht-gps" className="helper-text ht" data-error="wrong" data-success="right"></span>
                        <div id="out"></div>
                        <br /><br />
                        <button type="button" disabled={!continuar} className={["btn", "btn-raised", "forcewidth100", ((!continuar) ? "btn-secondary" : "btn-primary main_bg_color")].join(" ")} onClick={e => actualizarDatos(e)}>GUARDAR</button>
                    </form>
                </div>
                {showFooter && <Footer />}
                {/* {errorUbicacion ?
                    <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Atención</h5>
                                    <button onClick={e => setErrorUbicacion(false)} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Necesitamos validar tu ubicación para continuar</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={e => setErrorUbicacion(false)} className="btn btn-raised btn-primary main_bg_color">ENTENDIDO</button>
                                </div>
                            </div>
                        </div>
                    </div> : null} */}
            </div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )
}

export default IdentificacionEdit