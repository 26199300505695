import React, {useEffect, useState} from 'react'
/* Componentes */
import Header from '../../components/header'
import Ayuda from '../ayuda'
/* Librerías */
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
/* Funciones */
import {  evento } from '../../services/data'
import { configStyle } from '../../services/configStyle'

const AdvertenciaDispositivo = () => {

    const [dataOtorgante, setDataOtorgante] = useState([])
    const [isOpenByPivot, setIsOpenByPivot] = useState(false)
    const [nombreOtorgante, setNombreOtorgante] = useState(null)
    const history = useHistory()
    const [showHelp, setShowHelp] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            const data = JSON.parse(dataOtorganteLS)
            setDataOtorgante(data)
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const redireccionamiento = () =>{
        evento('Validacion dispositivo', 'Click', { description: 'CONTINUAR' }, true)
        setTimeout(() => {
            history.push('/inicio')
        }, 300);
    }

    return (
        <>
            <div className='main_gradient container-flex'>
                <Header show={() => setShowHelp(true)} />
                <div className='container-center-flex'>
                    <div className='main_text_container'>
                        <h1 className='animated fadeIn delay-1s'>Queremos que tengas la mejor experiencia</h1>
                        <p className='animated fadeIn delay-2s'>Tu dispositivo <b>no cumple</b> con los <b>requisitos mínimos</b> para garantizar una experiencia <b>óptima</b> en el proceso.</p>
                        <p className='animated fadeIn delay-2s'>Un dipositivo de gama <b>media</b> o <b>alta</b> es suficiente.</p>
                    </div>
                </div>
                <div className='container-bottom-flex'>
                    <div className='action_buttons noscroll_screen'>
                            <button onClick={() => {
                                setShowAlert(true)
                                evento('Validacion dispositivo', 'Click', { description: 'PROBARÉ EN ESTE DISPOSITIVO' }, true)
                                }} type='button' className="btn btn-raised btn-primary forcewidth100 main_bg_color animated fadeIn delay-3s">PROBARÉ EN ESTE DISPOSITIVO</button>
                    </div>
                </div>
            </div>
            {(showAlert) &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className={['modal-dialog', isMobile ? 'modal-center' : 'modal-center-desktop'].join(' ')} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><b>¿Estás seguro que deseas continuar?</b></h5>
                            </div>
                            <div className="modal-body">
                                <p>Por favor sé paciente y sigue las instrucciones cuidadosamente.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => {setShowAlert(false);  evento('Validacion dispositivo', 'Click', { description: 'CANCELAR' }, true)}} className="btn btn-primary forcewidth100 main_color">CANCELAR</button>
                                <button type="button" onClick={redireccionamiento} className="btn btn-raised btn-primary main_bg_color">CONTINUAR</button>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export default AdvertenciaDispositivo;
