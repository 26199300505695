import React from "react";
import { obtenerValorConfig } from '../../services/configStyle'

const LimitIntentsAlert = (props) => {
    const { uuidTransaccion, functionOnOut, dataOtorgante, pathname } = props;

    return (
        <div className="err_finale animate__animated animate__slideInUp">
            <div className="center_checks">
                <h5>Proceso no exitoso</h5>
                <p>Has superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(uuidTransaccion).split("-")[0]}</b> </p>
                <br />
                {
                    (pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") &&
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p><b>Llámanos al:</b></p>
                                <p><a className="help_phone" href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                            </div>
                            <div className="col">
                                <p><b>Escríbenos a:</b></p>
                                <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                            </div>
                        </div>
                    </div>
                }
                {(!window.opener) &&
                    <div className="action_buttons noscroll_screen">
                        <button className='btn btn-raised btn-primary forcewidth100 main_bg_color' onClick={functionOnOut}>ENTENDIDO</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default LimitIntentsAlert;