/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import Loader from "../components/loader";
import { enviarComprobante } from '../services/api.js';
import { isIOS, isMobile, withOrientationChange, isSafari } from 'react-device-detect';
import { hayExcepcion, statusError, statusData } from '../services/data';
import CropPhoto from './photoPreview';


class CameraPhotoComprobante extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        uuid: null,
        dataUser: [],
        dataOtorgante: [],
        dataOCRFront: {},
        isStart: false,
        proccessing: false,
        proccessingHolo: false,
        desktop: false,
        altura: 960,
        ancho: 720,
        showIdOverlay: false,
        intentsFront: 0,
        intentsBack: 0,
        apikey: "",
        hasResponse: false,
        hasFace: false,
        hasTextFront: false,
        hasTextBack: false,
        isHoloInstructions: true,
        showHoloAnimation: true,
        intentsData: 0,
        errorF: "",
        errorMsg: false,
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        invalidRight: false,
        invalidLeft: false,
        verBtnPreview: false,
        preview: false,
        imageUrl: null,
        blobImage: null
    }

    componentDidMount() {
        const component = this;
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }
        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (bloqueo == 'true') {
                this.state.intentsFront = Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"));
                //console.log("intentsFront: " + this.state.intentsFront);
            }
        }
        this.state.pathname = window.location.pathname;
        //console.log("bloque: " + bloqueo);
        if (!isMobile) {
            //console.log("es desktop");
            this.state.desktop = true;
            this.state.altura = 720;
            this.state.ancho = 960;
            this.setState({ showHoloAnimation: false })
            this.setState({ isHoloInstructions: false, isStart: true })
            var element = document.getElementById("video_wrt");
            element.classList.add("myVideo_rotate");
        } else {
            if (hayExcepcion()) {
                this.setState({ isHoloInstructions: false, isStart: true, showHoloAnimation: false })
            }
        }

        var deviceFuncional = "";
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                var device = devices[i];
                if (device.kind === 'videoinput') {
                    //console.log(device.label)
                    if ((device.label).includes("back")) {
                        if ((device.label).includes("0")) {
                            deviceFuncional = device.deviceId;
                            //console.log("deviceFuncional1", deviceFuncional);
                        }
                    }
                }
            }
            component.start(deviceFuncional);
        });
        //console.log("deviceFuncional2", deviceFuncional);
    }

    start = (deviceFuncional) => {
        const that = this;
        if (window.stream) {
            //console.log("cerrando");
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }

        const constraints = {
            audio: false,
            video: (deviceFuncional) ? {
                deviceId: { exact: deviceFuncional },
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            } : {
                    facingMode: "environment",
                    width: { ideal: 640 },
                    height: { ideal: 480 },
                    advanced: [
                        { width: 1280, height: 720 },
                        { aspectRatio: 4 / 3 }
                    ]
                }
        };

        //console.log("Reconfiguracion",constraints);
        that.localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch(
                (handleError) => {
                    //console.log("handleError",handleError)
                    that.setState({ errorVideo: true })
                }
            );

        that.canvas = document.querySelector('canvas');
        that.width = 320;
        that.height = 0;
        that.video = document.querySelector('video');
        that.elemento = document.getElementById("cuadro");
        that.cuenta = document.getElementById("numeros");
        that.titulo = document.getElementById("head_shop");
        let component = that;
        that.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    //console.log("disponibles");
                    component.setState({ streamStarted: true, errorVideo: false })
                } else {
                    //console.log("No disponibles");    
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);

        }).catch(e => {
            console.trace(e);
            that.setState({ errorVideo: true });
        })

    }

    takeFotoRight = () => {
        //console.log("en foto");
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl;
        setTimeout(() => {
            console.log("tomando foto");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                that.setState({ proccessing: true, loading: false });
                blob.lastModifiedDate = new Date();
                blob.name = "comprobante.png";
                console.log("name:" + blob.name);
                this.setState({ loading: false, proccessing: false, intentsFront: 0, intentsData: 0, preview: true, imageUrl: urlCreator.createObjectURL(blob) })
            }, 'image/png', 0.9);
        }, 1050);
    }

    capturarNext = (valor) => {
        if (valor === 0) {
            this.setState({ preview: false, verBtnPreview: false, imageUrl: null })
        } else {
            this.setState({ proccessing: true })
            console.log("entre a enviar comprobante");
            setTimeout(() => {
                enviarComprobante(this.state.blobImage)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log(response.data);
                            if (obtenerValorConfig(this.state.dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                                this.props.history.push({
                                    pathname: '/clausula'
                                });
                            } else if (obtenerValorConfig(this.state.dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                                this.props.history.push({
                                    pathname: '/autorizacion_solicitud'
                                });
                            } else {
                                this.props.history.push({
                                    pathname: '/video_token'
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        //console.log("e:", error);
                        this.setState({ errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                        this.statusF();
                    }
                    )
                    .finally(() => {
                        this.setState({ loading: false, proccessing: false });
                    })
            }, 500);
        }
    }

    initRecord = () => {
        this.setState({ loading: true })
        this.takeFotoRight()
    }
    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("Se quito componente video");
        });
    }

    sendData = () => {
        var jsonObj = {};
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        statusData("captura_id", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    statusF = () => {
        //console.log("entre a status ine");
        let id = (this.state.isPassport) ? "Pasaporte" : "INE"
        statusError("Captura ID", id + ":" + this.state.errorF, "error")
    }

    intentosBloqueo = () => {
        localStorage.setItem("bloqueo", "true");
        let id = (this.state.isPassport) ? "Pasaporte" : "INE"
        statusError("Captura ID", id + ":" + this.state.errorF, "cancelado")
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime())
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
        setTimeout(() => {
            window.close()
        }, 300);
    }

    checkCamera = () => {
        setTimeout(() => {
            //console.log("Camera:"+this.state.streamStarted);
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    cancelarCaptura = () => {
        this.setState({ loading: false, proccessing: false, hasTextFront: true, intentsFront: 0, intentsData: 0 })
        this.props.history.push("/preparacion_comprobante")
    }

    enviarImagen = (blobImage) => {
        this.setState({ proccessing: true })
        setTimeout(() => {
            enviarComprobante(blobImage)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data);
                        if (obtenerValorConfig(this.state.dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                            this.props.history.push({
                                pathname: '/clausula'
                            });
                        } else if (obtenerValorConfig(this.state.dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                            this.props.history.push({
                                pathname: '/autorizacion_solicitud'
                            });
                        } else {
                            this.props.history.push({
                                pathname: '/video_token'
                            });
                        }
                    }
                })
                .catch((error) => {
                    //console.log("e:", error);
                    this.setState({ errorMessage: "Por favor captura nuevamente tu comprobante.", errorToken: true, errorF: "Error en la captura de comprobante", token: null, loading: false, hasResponse: true })
                    this.statusF();
                }
                )
                .finally(() => {
                    this.setState({ loading: false, proccessing: false });
                })
        }, 500);
    }

    render() {
        const { isLandscape } = this.props;
        return (
            <div className="container">


                <div className="module_container overflow_hddn" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame">
                        <video id="video_wrt" playsInline></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                    </div>
                    <div className="module_gradient_overlay"></div>
                    <div className="module_title animate__animated animate__slideInDown">
                        <p>Captura la <b>portada</b> de tu documento</p>
                    </div>
                    <div className="alert_instruction">
                        <img height="24" src="images/evita_reflejos_warn.png" alt="" />
                    </div>
                    <div>
                        <img className={isMobile?"document_msk":"document_msk_dsk"} src={isMobile?"images/doc_check_msk_a.svg":"images/doc_check_msk_a_dsk.svg"} alt="" />
                    </div>

                    <div className="module_buttons animate__animated animate__fadeIn animate__delay-3s" style={{position:'fixed'}}>

                        <button type="button" onClick={() => this.initRecord()} className={["btn btn-raised btn-primary forcewidth100 main_bg_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "CAPTURAR COMPROBANTE")}</button>
                        <button type="button" onClick={() => this.cancelarCaptura()} className={["btn btn-raised btn-primary forcewidth100 cancel_btn main_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >CANCELAR</button>
                    </div>

                </div>

                {this.state.intentsFront >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) ? (<div className="err_finale  animate__animated animate__slideInUp">
                    <div className="center_checks"> {this.intentosBloqueo()}
                        <h5>Proceso no exitoso </h5>
                        <p>Has superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                        <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuid).split("-")[0]}</b> </p>
                        <br />
                        {
                            (this.state.pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") ?
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <p><b>Llámanos al:</b></p>
                                            <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                        </div>
                                        <div className="col">
                                            <p><b>Escríbenos a:</b></p>
                                            <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                        }
                        <div className="action_buttons noscroll_screen">
                            {
                                <Link to={"/" + this.state.apikey} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
                            }
                        </div>
                    </div>
                </div>) : ("")}

                {(this.state.proccessing) ? <Loader /> : ("")}

                {((this.state.loading && !this.state.showHoloAnimation) ? <div className="sprite_stay_a animado"></div> : (""))}
                {
                    (isLandscape) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>
                            </div>
                        </div>
                    ) : ("")
                }
                {(this.state.intentsData === 1) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">No se pudo leer correctamente tus datos, por favor vuelve a intentar</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ intentsData: 2 })
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
                {(this.state.errorMsg) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Parece que perdimos contacto, por favor revisa tu conexión a internet e intenta nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorMsg: false })
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>∫
                    </div>
                    :
                    ""
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Hemos detectado que la  <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>. Por favor, asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorVideo: false })
                                window.location.reload()
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }
                {this.state.preview ? <CropPhoto tipo="comprobante" imageUrl={this.state.imageUrl} enviarImagen={blobImage => this.enviarImagen(blobImage)} reload={() => { this.setState({ preview: false }) }} /> : ("")}
            </div>
        );
    }


}
CameraPhotoComprobante = withOrientationChange(CameraPhotoComprobante)
export default withRouter(CameraPhotoComprobante)