import React, { useState, useEffect } from 'react';
import { obtenerValorConfig } from './utilities/styles/configStyle'

const Header = (props) => {
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [pathname, setpathname] = useState('');

    useEffect(() => {
        if (props.apikey && props.dataOtorgante.length > 0) {
            setpathname(props.apikey);
            let informacionOtorgante = props.dataOtorgante;
            if (informacionOtorgante) {
                setDataOtorgante(informacionOtorgante);
            }
        }
    }, [props]);

    return (
        <>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo" src={(obtenerValorConfig(props.dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(props.dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
                {
                    (pathname === "i2MopevgEp2HSmOppDOR3MHUzT4SEZOi") ?
                        <a className="help_btn"><img src={process.env.PUBLIC_URL + '/images/btn_circle_help_c2all.svg'} alt="" onClick={e => props.show()} /></a>
                        :
                        ""
                }
            </div>
        </>
    );
}

export default Header;