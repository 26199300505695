import { isMobile } from 'react-device-detect';

export const configStyle = (data) => {
    document.documentElement.style.setProperty("--color-primary", obtenerValorConfig(data, "COLOR_PRIMARIO"));
    document.documentElement.style.setProperty("--color-secondary", obtenerValorConfig(data, "COLOR_SECUNDARIO"));
    document.documentElement.style.setProperty("--color-accent", obtenerValorConfig(data, "COLOR_ACENTUACION"));
    document.documentElement.style.setProperty("--color-text", obtenerValorConfig(data, "COLOR_DE_TEXTO"));
    let logo = (obtenerValorConfig(data, 'LOGO_OTORGANTE', ""));
    if (document.getElementById("imagenLogo")) document.getElementById("imagenLogo").src = logo;
}

export const obtenerValorConfig = (lista, elemento, valorPredeterminado) => {
    const elementoEncontrado = lista.find(function (item) {
        return item.idCampo === elemento
    })
    if (elementoEncontrado) {
        return elementoEncontrado.valor
    }

    return valorPredeterminado
}


export const obtenerValorUser = (lista, elemento, valorPredeterminado) => {
    const elementoEncontrado = lista.find(function (item) {
        return item.description === elemento
    })
    if (elementoEncontrado) {
        return elementoEncontrado.value
    }

    return valorPredeterminado
}

export const replaceValue = (texto, src, target) => {
    if (texto !== undefined) {
        let query = new RegExp(src, "gi");
        return texto.replace(query, target)
    }
}

export const positionDiv = (innerWidth, innerHeight) => {
    if (isMobile) {
        if (innerHeight >= 681 && innerWidth >= 360) {
            return 'fixed';
        } else {
            return 'relative';
        }
    } else {
        if (innerHeight > 515) {
            return 'fixed';
        } else {
            return 'relative';
        }
    }
}