/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
/* Componentes */
import Header from '../../components/header'
import Ayuda from '../ayuda'
import ErrorPermisos from '../../components/error_permisos'
import Loader from "../../components/loader"
/* Librerías */
import { useParams, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { isChrome, isEdge, isMobile, isIOS, isSafari, deviceDetect } from 'react-device-detect'
/* Funciones */
import { obtenerParametrosOtorgante, obtenerInfoDispositivo } from '../../services/api.js'
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import { evento, statusError } from '../../services/data'

const ComprobarDispositivo = () => {

    const { apikey } = useParams()
    const history = useHistory()
    const [hayCookies] = useState(navigator.cookieEnabled)
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [notSupported, setNotSupported] = useState(false)
    const [notSupportediOS, setNotSupportediOS] = useState(false)
    const [isCorrectApikey, setCorrectApikey] = useState(true)
    const [loading, setLoading] = useState(true)
    const [apiKey, setApiKey] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [id, setId] = useState('')
    const [errorPermisos, setErrorPermisos] = useState(false)
    const [permisosDenegados, setPermisosDenegados] = useState(false)
    const [videoDevices, setVideoDevices] = useState([])
    const [getResponseInfoDevice, setGeToResponseInfoDevice] = useState(false)
    const [getResponseDataOtorgante, setGetResponseDataOtorgante] = useState(false)
    const [validateDevice, setValidateDevice] = useState(null)
    const [intentosValidacion, setIntentosValidacion] = useState(0)

    useEffect(() => {
        const itemsDeleteLS = [
            "codes",
            "ocrFront",
            "ocrBack",
            "time",
            "bloqueo",
            "face",
            "fotos",
            "enlace",
            "data_user",
            "movil",
            "dataOtorgante",
            "apikeyOtorgante",
            "uuidTrx",
            "fechaVigencia",
            "uuidOtorgante",
            "fields",
            "links",
            "flag",
            "correoElectronico",
            "completado",
            'typeID',
            'deviceId',
            'externalReference',
            'scoreObject'
        ];
        let uuidUser = localStorage.getItem("uuidUser");
        if (uuidUser === null) {
            if (new URL(window.location).searchParams.get("cliente")) {
                localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
            } else {
                let newUuid = uuidv4();
                localStorage.setItem("uuidUser", newUuid);
            }
        } else {
            if (new URL(window.location).searchParams.get("cliente")) {
                localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
            }
        }

        itemsDeleteLS.forEach((element) => {
            localStorage.removeItem(element);
        });

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        } else {
            setApiKey(apikey)
        }
        localStorage.setItem("apikeyOtorgante", apikey)

        if (new URL(window.location).searchParams.get("uuidTrx")) {
            //console.log("hay uuidTrx");
            localStorage.setItem("uuidTrx", new URL(window.location).searchParams.get("uuidTrx"))
        } else {
            let trx_id = uuidv4();
            localStorage.setItem("uuidTrx", trx_id);
        }
        if (new URL(window.location).searchParams.get("scheme")) {
            //console.log("hay uuidTrx");
            localStorage.setItem("package", new URL(window.location).searchParams.get("scheme"))
        }

        if (new URL(window.location).searchParams.get('referer')) {
            localStorage.setItem('externalReference', new URL(window.location).searchParams.get('referer'))
        }

        if (new URL(window.location).searchParams.get('schema')) {
            localStorage.setItem('package', new URL(window.location).searchParams.get('schema'))
        }

        if (new URL(window.location).searchParams.get('jwt')) {
            localStorage.setItem('externalJWT', new URL(window.location).searchParams.get('jwt'))
        }

    }, [apikey])

    useEffect(() => {
        if (!isMobile && !isEdge && !isChrome) {
            //console.log("Desktop, not supported browser");
            setNotSupported(true)
            setLoading(false)
        } else if (isMobile && isIOS && !isSafari) {
            //console.log("iOS, not supported browser");
            setNotSupportediOS(true)
            setLoading(false)
        } else {
            getPermissions()
            obtenerParametros()
            validarDispositivo()
        }/*else if (isMobile && !isIOS && (!isFirefox && !isChromium)) {
            //console.log("Android, not supported browser");
            setNotSupported(true);
        }*/
    }, [])

    useEffect(() => {
        console.log('version 2.3.4')
        localStorage.setItem("completado", "false")
    }, [])

    useEffect(() => {
        setDataOtorgante(dataOtorgante)
        configStyle(dataOtorgante)
        localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante))
        let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""))
        document.getElementById("imagenLogo").src = logo
        let ID_OTORGANTE = (obtenerValorConfig(dataOtorgante, 'ID_OTORGANTE', ""))
        if (ID_OTORGANTE) {
            localStorage.setItem("uuidOtorgante", ID_OTORGANTE)
        }
        if (dataOtorgante.length > 0) {
            evento('Dispositvo', 'Step', deviceDetect(), true)
            evento('Dimensiones viewport', 'Información', { innerWidth: window.innerWidth, innerHeight: window.innerHeight }, true)
        }
    }, [dataOtorgante])

    useEffect(() => {
        if (videoDevices.length > 0) getDeviceId(videoDevices)
    }, [videoDevices])

    useEffect(() => {
        if (id && getResponseInfoDevice && getResponseDataOtorgante && dataOtorgante.length > 0) {
            setLoading(false)
            if (validateDevice === true) {
                history.push('/inicio')
            } else if (validateDevice === false) {
                history.push('/advertencia_dispositivo')
            } else if (intentosValidacion === 3 && !validateDevice) {
                history.push('/inicio')
            } else {
                history.push('/inicio')
            }
        }
    }, [id, getResponseInfoDevice, getResponseDataOtorgante, validateDevice, dataOtorgante, intentosValidacion])

    useEffect(() => {
        if (intentosValidacion > 0 && intentosValidacion < 3) {
            validarDispositivo()
        } else {
            setGeToResponseInfoDevice(true)
        }
    }, [intentosValidacion])

    const obtenerParametros = async () => {
        let tipo = ''
        let informacion = {}
        obtenerParametrosOtorgante(apikey, apiKey).then((data) => {
            if (data.status === 200) {
                setDataOtorgante(data.data.payload)
                informacion.status = data.status
                informacion.payload = data.data.payload
                tipo = 'Success'
                evento('Obtener parametros otorgante', tipo, informacion, true)
            }
        }).catch((error) => {
            setCorrectApikey(false);
            statusError('inicio_page', 'Error al obtener los parámetros del otorgantes', 'error')
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message
                informacion.status = status
                tipo = 'Error'
            } else {
                informacion.error = error.toString()
                tipo = 'Exception'
            }
            setCorrectApikey(false)
            evento('Obtener parametros otorgante', tipo, informacion, false)
        }).finally(() => setGetResponseDataOtorgante(true))
    }

    /* Obtener permisos */
    const getPermissions = () => {
        setPermisosDenegados(false)
        return navigator.mediaDevices.getUserMedia(
            { video: true, audio: true }
        ).then(async (mediaStream) => {
            const videoTag = document.createElement('video')
            if (videoTag) {
                videoTag.srcObject = mediaStream
            }
            stopTrack(mediaStream)
            await getVideoDevices()
        }).catch(handleErrorGeUserMedia)
    }

    /* Obtener dipositivos de video */
    const getVideoDevices = () => {
        let newArrayVideoDevices = []
        return navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach(device => {
                if (device.kind === 'videoinput') {
                    newArrayVideoDevices.push(device)
                }
            });
            evento('Obtener dispositivos de video', 'Medios dispositivo', { dispositivos: newArrayVideoDevices }, true)
            setVideoDevices(newArrayVideoDevices)
        }).catch(error => {
            evento('Obtener dispositivos de video', 'Medios dispositivo', { error: error.toString() }, true)
        });
    }

    /* Obtener deviceId */
    const getDeviceId = async (devices) => {
        const url = new URL(window.location);
        let search_camera = url.searchParams.get('camera')
        if (devices.length > 2) {
            for (const device of devices) {
                try {
                    const mediaStream = await loadStream(device);
                    const mediaStreamTrack = mediaStream.getVideoTracks()[0]
                    const { label } = mediaStreamTrack;
                    // if (label && label.includes((search_camera === 'front') ? search_camera : 'back') && label.includes((search_camera === 'front') ? '1' : '0')) {
                    if (label && ((label.includes((search_camera === 'front') ? search_camera : 'back') && label.includes((search_camera === 'front') ? '1' : '0')) ||
                        ((label.includes((search_camera === 'frontal') ? search_camera : 'trasera'))))) {
                        evento('Obtener etiqueta del dispositivo de video', 'Medios dispositivo', { label: label }, true)
                        const { deviceId } = mediaStreamTrack.getCapabilities()
                        evento('Obtener id del dispositivo de video (principal)', 'Medios dispositivo', { deviceId: deviceId }, true)
                        setDeviceId(deviceId)
                        stopTrack(mediaStream)
                        break;
                    }
                    stopTrack(mediaStream)
                } catch (error) {
                    evento('Error al obtener id del dispositivo de video', 'Medios dispositivo', { error: error.toString() }, true)
                    handleErrorGeUserMedia(error)
                }
            }
        } else {
            (isMobile && search_camera === 'front') ? setDeviceId('user') : setDeviceId('environment')
        }
    }

    /* Asignar deviceId */
    const setDeviceId = (id) => {
        setId(id)
        localStorage.setItem('deviceId', id)
    }

    /* Obtener stream */
    const loadStream = (device) => {
        return navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: { exact: device.deviceId },
            }
        })
    }

    /* Manejo de errores */
    const handleErrorGeUserMedia = (error) => {
        if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
            //required track is missing 
            evento('Captura ID', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true)
        } else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
            //webcam or mic are already in use 
            evento('Captura ID', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true)
        } else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
            //constraints can not be satisfied by avb. devices 
            evento('Captura ID', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true)
        } else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
            //permission denied in browser 
            setPermisosDenegados(true)
            evento('Captura ID', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true)
        } else if (error.name === "TypeError" || error.name === "TypeError") {
            //empty constraints object 
            evento('Captura ID', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true)
        } else {
            //other errors 
            evento('Captura ID', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true)
        }
        setErrorPermisos(true)
    }

    /* Detener track stream */
    const stopTrack = (mediaStream) => {
        mediaStream.getTracks().forEach(track => {
            track.stop()
        })
    }

    /* Validar dispositivo */
    const validarDispositivo = async () => {
        let tipo = ''
        let informacion = {}
        try {
            const response = await obtenerInfoDispositivo()
            const { status, data } = response
            if (status === 200) {
                informacion.status = data.status
                informacion.payload = data.payload
                evento('Validar dispositivo', 'Success', informacion, true)
                const { payload: { status } } = data
                if (status === 'DISPOSITIVO_PERMITIDO' || status === 'DISPOSITIVO_NO_ENCONTRADO')
                    setValidateDevice(true)
                else
                    setValidateDevice(false)
                setGeToResponseInfoDevice(true)
            }
        } catch (error) {
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message
                informacion.status = status
                tipo = 'Error'
            } else {
                informacion.error = error.toString()
                tipo = 'Exception'
            }
            evento('Validar dispositivo', tipo, informacion, false)
            setIntentosValidacion(intentosValidacion + 1)
        }
    }

    const reload = () => {
        window.location.reload()
    }

    return (
        <>
            <div className='main_gradient container-flex'>
                <Header show={() => setShowHelp(true)} />
                <div className='container-center-flex'>
                    <div className='main_text_container'>
                        <h1 className='animated fadeIn delay-1s'>Validando dispositivo</h1>
                        <p className='animated fadeIn delay-2s'>Solo tomará <b>unos segundos</b>, gracias por tu <b>paciencia</b>.</p>
                    </div>
                </div>
            </div>
            {((notSupported) && <div className="browser_not_comp show_me_bx">
                <div className="top_bx">
                    <img src="images/browser_alert_icon.svg" alt="" />
                    <h4>Lo sentimos tu navegador actual no es <br />
                        compatible con nuestra plataforma.</h4>
                    <div className="divider"></div>
                </div>
                <div className="bottom_bx">
                    <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                    <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                        <img src="images/browser_chrome.png" width="96" alt="" />
                        <a href="https://www.google.com/chrome/">Chrome</a>
                        <small>Google</small>
                    </div>
                    <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                        <img src="images/browser_edge.png" width="96" alt="" />
                        <a href="https://www.microsoft.com/en-us/edge">Edge</a>
                        <small>Microsoft</small>
                    </div>
                </div>

            </div>)}
            {(notSupportediOS) && <div className="browser_not_comp show_me_bx">
                <div className="top_bx">
                    <img src="images/browser_alert_icon.svg" alt="" />
                    <h4>Apple limita el uso de aplicaciones avanzadas en este navegador.</h4>
                    <div className="divider"></div>
                </div>
                <div className="bottom_bx">
                    <p><small>Para continuar con tu proceso utiliza o descarga Safari para iOS.</small></p>
                    <div className="browser_bx ios_browser">
                        <img src="images/browser_ios.png" width="96" alt="" />
                        <a href="https://apps.apple.com/mx/app/safari/id1146562112?l=en">Safari</a>
                        <small>Apple</small>
                    </div>
                </div>

            </div>}
            {(!hayCookies) && <div className="err_finale  animated slideInUp">
                <div className="center_checks">
                    <h5>Verifica tu navegador</h5>
                    <p>Para acceder a este sitio es necesario habilitar el uso de Cookies
                    </p>
                    <br />
                    <div className="action_buttons noscroll_screen">
                        <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">REINTENTAR</button>
                    </div>
                </div>
            </div>}

            {(!isCorrectApikey) &&
                <div className="err_finale  animated slideInUp">
                    <div className="center_checks">
                        <h5>Verifica tu URL</h5>
                        <p>Lo sentimos la dirección que buscas no se encuentra o es incorrecta, por favor verifica la información e intenta nuevamente.
                        </p>
                        <br />
                        <div className="action_buttons noscroll_screen">
                            <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">REINTENTAR</button>
                        </div>
                    </div>
                </div>}
            {(loading) && <Loader />}
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {errorPermisos && <ErrorPermisos permisosDenegados={permisosDenegados} setErrorPermisos={setErrorPermisos} permisoCamara={getPermissions} />}
        </>
    );
}

export default ComprobarDispositivo
