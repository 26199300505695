import { enviarEvento } from "../../services/api";

const token = process.env.REACT_APP_TOKEN_EVENTS;

export const evento = (paso, tipo, data, finished, configuration, uuidOtorgante = '') => {
    let newHeaders = [];
    const dato = JSON.stringify(data);
    let fecha = "";
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    fecha = `${year}-${(month > 9) ? month : `0${month}`}-${(day > 9) ? day : `0${day}`}_${(hour > 9) ? hour : `0${hour}`}:${(min > 9) ? min : `0${min}`}:${(seconds > 9) ? seconds : `0${seconds}`}`;
    let objeto = {
        uuid_clt: configuration.uuidUser,
        uuid_trx: configuration.uuidTransaccion,
        uuid_oto: configuration.uuidOtorgante? configuration.uuidOtorgante: uuidOtorgante,
        apikey: configuration.apikey,
        paso: paso,
        tipo: tipo,
        dato: dato,
        finished: finished,
        time_stamp: fecha
    }

    newHeaders.push({ key: 'accept', value: 'application/json' });
    newHeaders.push({ key: 'xc-token', value: token });

    return enviarEvento(configuration,objeto, newHeaders).then(response => {
        //console.log('RESPONSE', response);
        return
    }).catch(error => console.log('ERROR', error));
}

export const sendEventClick = (paso, description, objectInformation, configuration) => {
    let objeto = { description: description };
    let objetoEvento = {
        ...objeto,
        ...objectInformation
    }
    evento(paso, 'Click', objetoEvento, true, configuration);
}