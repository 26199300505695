import React, { Fragment } from "react";

const InfoIne = ({close}) => {
    return (
        <Fragment>
            <div className="modal" tabIndex="-1" role="dialog" id="myModal" style={{display:'block'}}>
                <div className="modal-dialog" role="document" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Validación INE</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <p>Identifica la versión de tu identificación y valida la información.</p>
                            <p><b>Frente</b></p>
                            <img src="./images/ines/credencial-modeloC_f.png" className="img-fluid" alt="Responsive image"/>
                            <p><b>Reverso</b></p>
                            <img src="./images/ines/credencial-modeloEG_v.png" className="img-fluid" alt="Responsive image"/>
                            <img src="./images/ines/credencial-modeloD_v.png" className="img-fluid" alt="Responsive image"/>
                            <img src="./images/ines/credencial-modeloC_v.png" className="img-fluid" alt="Responsive image"/>
                        </div>
                        
                        <div className="modal-footer" id="closeModal">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={close}>Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default InfoIne;
