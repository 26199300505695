import React, { useState, Fragment, useEffect, useRef, useCallback } from 'react';
import { isIOS, isSafari, isMobile, isAndroid } from 'react-device-detect';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { calculateX, calculateY, calculateWidth, calculateHeight, evento, getX, getY, getWidth, getHeight } from '../services/data';

const CropPhoto = ({ tipo, imageUrl, enviarImagen, reload, side }) => { 
    const x = getX();
    const y = getY();
    const width = getWidth();
    const height = getHeight();
    const [doCrop, setDoCrop] = useState(true);
    const imageRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [guardar, setGuardar] = useState(false);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [capturing, setCapturing] = useState(true);
    const [crop, setCrop] = useState(
        (tipo === "identificacion") ?
            (isMobile) ?
                (isAndroid) ?
                    {
                        unit: '%', // default, can be 'px' or '%'
                        x: x,
                        y: y,
                        width: width,
                        height: height,
                    }
                    /* (window.innerHeight > 718 && window.innerWidth > 360) ?
                        {
                            unit: 'px', // default, can be 'px' or '%'
                            x: calculateX(window.innerWidth, 3.39, "number"),
                            y: calculateY(window.innerHeight, 28.94, "number"),
                            width: calculateWidth(window.innerWidth, 92.47, "number"),
                            height: calculateHeight(window.innerHeight, 31.26, "number")
                        }
                        :
                        (window.innerHeight > 700 && window.innerHeight < 718 && window.innerWidth === 360) ?
                            {
                                unit: 'px', // default, can be 'px' or '%'
                                x: calculateX(window.innerWidth, 3.61, "number"),
                                y: calculateY(window.innerHeight, 21.17, "number"),
                                width: calculateWidth(window.innerWidth, 93.61, "number"),
                                height: calculateHeight(window.innerHeight, 30.71, "number")
                            }
                            :
                            (window.innerHeight > 718 && window.innerWidth <= 360) ?
                                {
                                    unit: 'px', // default, can be 'px' or '%'
                                    x: calculateX(window.innerWidth, 2.77, "number"),
                                    y: calculateY(window.innerHeight, 18.45, "number"),
                                    width: calculateWidth(window.innerWidth, 94.44, "number"),
                                    height: calculateHeight(window.innerHeight, 32.56, "number")
                                }
                                :
                                (window.innerHeight >= 696 && window.innerHeight < 700 && window.innerWidth === 360) ?
                                    {
                                        unit: 'px', // default, can be 'px' or '%'
                                        x: calculateX(window.innerWidth, 3.05, "number"),
                                        y: calculateY(window.innerHeight, 22.84, "number"),
                                        width: calculateWidth(window.innerWidth, 94.44, "number"),
                                        height: calculateHeight(window.innerHeight, 31.60, "number")
                                    }
                                    :
                                    (window.innerHeight > 640 && window.innerWidth > 360) ?
                                        {
                                            unit: 'px', // default, can be 'px' or '%'
                                            x: calculateX(window.innerWidth, 3.39, "number"),
                                            y: calculateY(window.innerHeight, 34.12, "number"),
                                            width: calculateWidth(window.innerWidth, 94.66, "number"),
                                            height: calculateHeight(window.innerHeight, 35.79, "number")
                                        }
                                        :
                                        (window.innerHeight === 630 && window.innerWidth === 360) ?
                                            {
                                                unit: 'px', // default, can be 'px' or '%'
                                                x: calculateX(window.innerWidth, 1.34, "number"),
                                                y: calculateY(window.innerHeight, 28.62, "number"),
                                                width: calculateWidth(window.innerWidth, 95.97, "number"),
                                                height: calculateHeight(window.innerHeight, 40.16, "number")
                                            }
                                            :
                                            (window.innerHeight === 640) ?
                                                {
                                                    unit: 'px', // default, can be 'px' or '%'
                                                    x: calculateX(window.innerWidth, 3.05, "number"),
                                                    y: calculateY(window.innerHeight, 29.37, "number"),
                                                    width: calculateWidth(window.innerWidth, 94.16, "number"),
                                                    height: calculateHeight(window.innerHeight, 34.21, "number")
                                                }
                                                :
                                                (window.innerHeight > 657) ?
                                                    {
                                                        unit: 'px', // default, can be 'px' or '%'
                                                        x: calculateX(window.innerWidth, 2.77, "number"),
                                                        y: calculateY(window.innerHeight, 23.49, "number"),
                                                        width: calculateWidth(window.innerWidth, 94.44, "number"),
                                                        height: calculateHeight(window.innerHeight, 35.76, "number")
                                                    }
                                                    :
                                                    (window.innerHeight > 640 && window.innerHeight <= 657) ?
                                                        {
                                                            unit: 'px', // default, can be 'px' or '%'
                                                            x: calculateX(window.innerWidth, 3.05, "number"),
                                                            y: calculateY(window.innerHeight, 27.85, "number"),
                                                            width: calculateWidth(window.innerWidth, 94.44, "number"),
                                                            height: calculateHeight(window.innerHeight, 33.63, "number")
                                                        }
                                                        :
                                                        (window.innerHeight <= 512 && window.innerWidth > 320) ?
                                                            {
                                                                unit: 'px', // default, can be 'px' or '%'
                                                                x: calculateX(window.innerWidth, 1.11, "number"),
                                                                y: calculateY(window.innerHeight, 39.06, "number"),
                                                                width: calculateWidth(window.innerWidth, 98.61, "number"),
                                                                height: calculateHeight(window.innerHeight, 48.82, "number")
                                                            }
                                                            :
                                                            (window.innerHeight > 512 && window.innerHeight < 572 && window.innerWidth > 320) ?
                                                                {
                                                                    unit: 'px', // default, can be 'px' or '%'
                                                                    x: calculateX(window.innerWidth, 1.11, "number"),
                                                                    y: calculateY(window.innerHeight, 27.81, "number"),
                                                                    width: calculateWidth(window.innerWidth, 98.61, "number"),
                                                                    height: calculateHeight(window.innerHeight, 48.82, "number")
                                                                }
                                                                :
                                                                (window.innerHeight <= 572 && window.innerWidth === 320) ?
                                                                    {
                                                                        unit: 'px', // default, can be 'px' or '%'
                                                                        x: calculateX(window.innerWidth, 2.5, "number"),
                                                                        y: calculateY(window.innerHeight, 21.32, "number"),
                                                                        width: calculateWidth(window.innerWidth, 91.02, "number"),
                                                                        height: calculateHeight(window.innerHeight, 39.16, "number")
                                                                    }
                                                                    :
                                                                    {
                                                                        unit: 'px', // default, can be 'px' or '%'
                                                                        x: calculateX(window.innerWidth, 2.04, "number"),
                                                                        y: calculateY(window.innerHeight, 34.89, "number"),
                                                                        width: calculateWidth(window.innerWidth, 95.31, "number"),
                                                                        height: calculateHeight(window.innerHeight, 37.75, "number")
                                                                    } */
                    :
                    (window.innerWidth === 375 && window.innerHeight <= 553) ?
                        {
                            unit: '%', // default, can be 'px' or '%'
                            x: 1,
                            y: 30,
                            width: 98,
                            height: 50,
                        }
                        :
                        (window.innerWidth > 375 && window.innerHeight >= 664) ?
                            {
                                unit: 'px', // default, can be 'px' or '%'
                                x: calculateX(window.innerWidth, 1.53, "number"),
                                y: calculateY(window.innerHeight, (36.29 / 1.5), "number"),
                                width: calculateWidth(window.innerWidth, 95.64, "number"),
                                height: calculateHeight(window.innerHeight, (36.29 * 1.5), "number")
                            }
                            :
                            (window.innerWidth <= 375 && window.innerHeight >= 633) ?
                                {
                                    unit: 'px', // default, can be 'px' or '%'
                                    x: calculateX(window.innerWidth, 0.8, "number"),
                                    y: calculateY(window.innerHeight, (37.12 / 1.5), "number"),
                                    width: calculateWidth(window.innerWidth, 97.86, "number"),
                                    height: calculateHeight(window.innerHeight, (37.28 * 1.5), "number")
                                }
                                :
                                {
                                    unit: 'px', // default, can be 'px' or '%'
                                    x: calculateX(window.innerWidth, 1.06, "number"),
                                    y: calculateY(window.innerHeight, (47.01 / 1.5), "number"),
                                    width: calculateWidth(window.innerWidth, 97.33, "number"),
                                    height: calculateHeight(window.innerHeight, (43.21 * 1.5), "number")
                                }
                :
                (window.innerHeight > 800) ?
                    {
                        unit: '%', // default, can be 'px' or '%'
                        x: (window.innerWidth >= 1200) ? 15 : (window.innerWidth >= 1150) ? 20 : (window.innerWidth > 1050) ? 20 : (window.innerWidth >= 900) ? 14 : 32,
                        y: (window.innerWidth >= 1200) ? 15 : (window.innerWidth >= 1150) ? 26 : (window.innerWidth > 1050) ? 25 : (window.innerWidth >= 900) ? 20 : 26,
                        width: (window.innerWidth >= 1200) ? 70 : (window.innerWidth >= 1150) ? 61 : (window.innerWidth > 1050) ? 61 : (window.innerWidth >= 900) ? 74 : 38,
                        height: (window.innerWidth >= 1200) ? 65 : (window.innerWidth >= 1150) ? 52 : (window.innerWidth > 1050) ? 53 : (window.innerWidth >= 900) ? 66 : 33
                    }
                    :
                    {
                        unit: '%', // default, can be 'px' or '%'
                        x: (window.innerWidth >= 1200) ? 7 : (window.innerWidth >= 1150) ? 15 : (window.innerWidth > 1050) ? 14 : (window.innerWidth >= 900) ? 14 : 32,
                        y: (window.innerWidth >= 1200) ? 7 : (window.innerWidth >= 1150) ? 18 : (window.innerWidth > 1050) ? 17 : (window.innerWidth >= 900) ? 17 : 27,
                        width: (window.innerWidth >= 1200) ? 85 : (window.innerWidth >= 1150) ? 72 : (window.innerWidth > 1050) ? 72 : (window.innerWidth >= 900) ? 73 : 35,
                        height: (window.innerWidth >= 1200) ? 77 : (window.innerWidth >= 1150) ? 64 : (window.innerWidth > 1050) ? 65 : (window.innerWidth >= 900) ? 65 : 31
                    }
            :
            (isMobile) ?
                (isAndroid) ?
                    (window.innerHeight > 718 && window.innerWidth > 360) ?
                        {
                            unit: 'px', // default, can be 'px' or '%'
                            x: calculateX(window.innerWidth, 12.62, "number"),
                            y: calculateY(window.innerHeight, 18.47, "number"),
                            width: calculateWidth(window.innerWidth, 75.24, "number"),
                            height: calculateHeight(window.innerHeight, 51.93, "number")
                        }
                        :
                        (window.innerHeight > 700 && window.innerHeight < 718 && window.innerWidth === 360) ?
                            {
                                unit: 'px', // default, can be 'px' or '%'
                                x: calculateX(window.innerWidth, 11.94, "number"),
                                y: calculateY(window.innerHeight, 16.69, "number"),
                                width: calculateWidth(window.innerWidth, 76.38, "number"),
                                height: calculateHeight(window.innerHeight, 49.36, "number")
                            }
                            :
                            (window.innerHeight > 718 && window.innerWidth <= 360) ?
                                {
                                    unit: 'px', // default, can be 'px' or '%'
                                    x: calculateX(window.innerWidth, 5.83, "number"),
                                    y: calculateY(window.innerHeight, 16.41, "number"),
                                    width: calculateWidth(window.innerWidth, 86.94, "number"),
                                    height: calculateHeight(window.innerHeight, 52.10, "number")
                                }
                                :
                                (window.innerHeight >= 696 && window.innerHeight < 700 && window.innerWidth === 360) ?
                                    {
                                        unit: 'px', // default, can be 'px' or '%'
                                        x: calculateX(window.innerWidth, 12.77, "number"),
                                        y: calculateY(window.innerHeight, 17.95, "number"),
                                        width: calculateWidth(window.innerWidth, 75, "number"),
                                        height: calculateHeight(window.innerHeight, 49.56, "number")
                                    }
                                    :
                                    (window.innerHeight > 640 && window.innerWidth > 360) ?
                                        {
                                            unit: 'px', // default, can be 'px' or '%'
                                            x: calculateX(window.innerWidth, 9.22, "number"),
                                            y: calculateY(window.innerHeight, 18.34, "number"),
                                            width: calculateWidth(window.innerWidth, 81.31, "number"),
                                            height: calculateHeight(window.innerHeight, 59.33, "number")
                                        }
                                        :
                                        (window.innerHeight === 630 && window.innerWidth === 360) ?
                                            {
                                                unit: 'px', // default, can be 'px' or '%'
                                                x: calculateX(window.innerWidth, 13.03, "number"),
                                                y: calculateY(window.innerHeight, 22.53, "number"),
                                                width: calculateWidth(window.innerWidth, 72.67, "number"),
                                                height: calculateHeight(window.innerHeight, 54.20, "number")
                                            }
                                            :
                                            (window.innerHeight === 640) ?
                                                {
                                                    unit: 'px', // default, can be 'px' or '%'
                                                    x: calculateX(window.innerWidth, 11.94, "number"),
                                                    y: calculateY(window.innerHeight, 19.84, "number"),
                                                    width: calculateWidth(window.innerWidth, 74.44, "number"),
                                                    height: calculateHeight(window.innerHeight, 54.37, "number")
                                                }
                                                :
                                                (window.innerHeight > 657) ?
                                                    {
                                                        unit: 'px', // default, can be 'px' or '%'
                                                        x: calculateX(window.innerWidth, 5.18, "number"),
                                                        y: calculateY(window.innerHeight, 11.89, "number"),
                                                        width: calculateWidth(window.innerWidth, 88.70, "number"),
                                                        height: calculateHeight(window.innerHeight, 67.54, "number")
                                                    }
                                                    :
                                                    (window.innerHeight > 640 && window.innerHeight <= 657) ?
                                                        {
                                                            unit: 'px', // default, can be 'px' or '%'
                                                            x: calculateX(window.innerWidth, 11.38, "number"),
                                                            y: calculateY(window.innerHeight, 18.72, "number"),
                                                            width: calculateWidth(window.innerWidth, 76.38, "number"),
                                                            height: calculateHeight(window.innerHeight, 53.42, "number")
                                                        }
                                                        :
                                                        (window.innerHeight > 490 && window.innerWidth > 320) ?
                                                            {
                                                                unit: 'px', // default, can be 'px' or '%'
                                                                x: calculateX(window.innerWidth, 6.24, "number"),
                                                                y: calculateY(window.innerHeight, 16.13, "number"),
                                                                width: calculateWidth(window.innerWidth, 88.20, "number"),
                                                                height: calculateHeight(window.innerHeight, 84.83, "number")
                                                            }
                                                            :
                                                            (window.innerHeight <= 572 && window.innerWidth === 320) ?
                                                                {
                                                                    unit: 'px', // default, can be 'px' or '%'
                                                                    x: calculateX(window.innerWidth, 5.93, "number"),
                                                                    y: calculateY(window.innerHeight, 18.79, "number"),
                                                                    width: calculateWidth(window.innerWidth, 83.75, "number"),
                                                                    height: calculateHeight(window.innerHeight, 64.14, "number")
                                                                }
                                                                :
                                                                (window.innerHeight > 512 && window.innerHeight < 572 && window.innerWidth > 320) ?
                                                                    {
                                                                        unit: 'px', // default, can be 'px' or '%'
                                                                        x: calculateX(window.innerWidth, 1.38, "number"),
                                                                        y: calculateY(window.innerHeight, 29.06, "number"),
                                                                        width: calculateWidth(window.innerWidth, 96.87, "number"),
                                                                        height: calculateHeight(window.innerHeight, 40.69, "number")
                                                                    }
                                                                    :
                                                                    {
                                                                        unit: 'px', // default, can be 'px' or '%'
                                                                        x: calculateX(window.innerWidth, 2.04, "number"),
                                                                        y: calculateY(window.innerHeight, 34.89, "number"),
                                                                        width: calculateWidth(window.innerWidth, 95.31, "number"),
                                                                        height: calculateHeight(window.innerHeight, 37.75, "number")
                                                                    }
                    :
                    (window.innerWidth === 375 && window.innerHeight <= 553) ?
                        {
                            unit: 'px', // default, can be 'px' or '%'
                            x: calculateX(window.innerWidth, (4.52 * 0.3), "number"),
                            y: calculateY(window.innerHeight, (23.95 / 2), "number"),
                            width: calculateWidth(window.innerWidth, 90.93, "number"),
                            height: calculateHeight(window.innerHeight, (75.12 * 1.5), "number")
                        }
                        :
                        (window.innerWidth > 375 && window.innerHeight >= 664) ?
                            {
                                unit: 'px', // default, can be 'px' or '%'
                                x: calculateX(window.innerWidth, (5.71 * 0.3), "number"),
                                y: calculateY(window.innerHeight, (13.23 / 2), "number"),
                                width: calculateWidth(window.innerWidth, 89.24, "number"),
                                height: calculateHeight(window.innerHeight, (68.2 * 1.5), "number")
                            }
                            :
                            (window.innerWidth <= 375 && window.innerHeight >= 633) ?
                                {
                                    unit: 'px', // default, can be 'px' or '%'
                                    x: calculateX(window.innerWidth, (4.26 * 0.3), "number"),
                                    y: calculateY(window.innerHeight, (14.06 / 2), "number"),
                                    width: calculateWidth(window.innerWidth, 91.46, "number"),
                                    height: calculateHeight(window.innerHeight, (69.19 * 1.5), "number")
                                }
                                :
                                {
                                    unit: 'px', // default, can be 'px' or '%'
                                    x: calculateX(window.innerWidth, (1.06 * 0.3), "number"),
                                    y: calculateY(window.innerHeight, (47.01 / 2), "number"),
                                    width: calculateWidth(window.innerWidth, 97.33, "number"),
                                    height: calculateHeight(window.innerHeight, (43.21 * 1.5), "number")
                                }
                :
                {
                    unit: '%', // default, can be 'px' or '%'
                    x: (window.innerWidth >= 1200) ? 30 : (window.innerWidth >= 1150) ? 28 : (window.innerWidth > 1050) ? 25 : 22,
                    y: (window.innerWidth >= 1200) ? 14 : (window.innerWidth >= 1150) ? 7 : (window.innerWidth > 1050) ? 5 : 1,
                    width: (window.innerWidth >= 1200) ? 41 : (window.innerWidth >= 1150) ? 45 : (window.innerWidth > 1050) ? 51 : 57,
                    height: (window.innerWidth >= 1200) ? 83 : (window.innerWidth >= 1150) ? 88 : (window.innerWidth > 1050) ? 92 : 99
                }
    );

    useEffect(() => {
        //console.log("innerWidth", window.innerWidth);
        //console.log("innerHeight", window.innerHeight);
        let instruccion = document.getElementById("instruccion");
        let reactCrop = document.querySelector('.ReactCrop');
        let divCanvas = document.getElementById("div-canvas");
        let divCheck = document.getElementById("div-check");

        switch (tipo) {
            case "identificacion":
                instruccion.innerHTML = "<b>Ajusta</b> tu identificación";

                if (!isMobile) {
                    reactCrop.style.margin = '0 auto';
                    if (window.innerWidth >= 1400 && window.innerHeight >= 726) {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.65)';
                        reactCrop.style.bottom = '15%';

                        /* Div donde se muestra el recorte*/
                        divCanvas.style.transform = 'scale(0.65)';
                        divCanvas.style.top = '35%';

                        /* Div donde se encuentra el check*/
                        divCheck.style.top = '95%';
                    } else {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.55)';
                        reactCrop.style.bottom = '20%';

                        /* Div donde se muestra el recorte*/
                        divCanvas.style.transform = 'scale(0.6)';
                        divCanvas.style.top = '45%';

                        /* Div donde se encuentra el check*/
                        divCheck.style.top = '105%';
                    }
                } else {
                    /* Div donde se muestra el recorte*/
                    divCanvas.style.top = '25%';

                    /* Div donde se encuentra el check*/
                    divCheck.style.top = '70%';

                    if (window.innerHeight === 669 && window.innerWidth === 424) {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.6)';
                        reactCrop.style.bottom = '19%';
                    } else if (window.innerHeight > 657) {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.75)';
                        reactCrop.style.bottom = '13%';
                    } else if (window.innerHeight > 640 && window.innerHeight <= 657) {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.75)';
                        reactCrop.style.bottom = '14.5%';
                    } else if (window.innerHeight === 640) {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.7)';
                        reactCrop.style.bottom = '15%';
                    } else if (window.innerHeight >= 516 && window.innerWidth <= 320) {
                        if (isAndroid) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.57)';
                            reactCrop.style.bottom = '22.5%';

                            /* Div donde se muestra el recorte*/
                            divCanvas.style.top = '30%';

                            /* Div donde se encuentra el check*/
                            divCheck.style.top = '75%';
                        }
                    } else if (window.innerHeight > 568) {
                        if (isAndroid) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.68)';
                            reactCrop.style.bottom = '17%';
                        } else {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.65)';
                            reactCrop.style.bottom = '18%';
                        }
                    } else {
                        if (isAndroid) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.5)';
                            reactCrop.style.bottom = '25.5%';

                            /* Div donde se muestra el recorte*/
                            divCanvas.style.top = '30%';

                            /* Div donde se encuentra el check*/
                            divCheck.style.top = '75%';
                        } else {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.55)';
                            reactCrop.style.bottom = '23.5%';
                        }

                        /* Div donde se muestra el recorte*/
                        divCanvas.style.transform = 'scale(0.9)';
                    }
                }

                break;
            case "comprobante":
                instruccion.innerHTML = "<b>Ajusta</b> tu comprobante";

                if (!isMobile) {
                    reactCrop.style.margin = '0 auto';
                    if (window.innerHeight > 800) {
                        if (window.innerWidth >= 1400) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.6)';
                            reactCrop.style.bottom = '18%';

                            /* Div donde se muestra el recorte*/
                            divCanvas.style.transform = 'scale(0.75)';
                            divCanvas.style.top = '55%';

                            /* Div donde se encuentra el check*/
                            divCheck.style.top = '130%';
                        } else {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.8)';
                            reactCrop.style.bottom = '10%';

                            /* Div donde se muestra el recorte*/
                            divCanvas.style.transform = 'scale(0.9)';
                            divCanvas.style.top = '55%';

                            /* Div donde se encuentra el check*/
                            divCheck.style.top = '135%';
                        }

                    } else {
                        if (window.innerWidth >= 1400) {
                            if (window.innerHeight >= 715) {
                                /* Div donde se encuentra el crop*/
                                reactCrop.style.transform = 'scale(0.55)';
                                reactCrop.style.bottom = '21%';

                                /* Div donde se muestra el recorte*/
                                divCanvas.style.transform = 'scale(0.7)';
                                divCanvas.style.top = '55%';

                                /* Div donde se encuentra el check*/
                                divCheck.style.top = '130%';
                            } else {
                                /* Div donde se encuentra el crop*/
                                reactCrop.style.transform = 'scale(0.5)';
                                reactCrop.style.bottom = '24%';

                                /* Div donde se muestra el recorte*/
                                divCanvas.style.transform = 'scale(0.55)';
                                divCanvas.style.top = '55%';

                                /* Div donde se encuentra el check*/
                                divCheck.style.top = '120%';
                            }

                        } else {
                            if (window.innerHeight >= 715) {
                                /* Div donde se encuentra el crop*/
                                reactCrop.style.transform = 'scale(0.55)';
                                reactCrop.style.bottom = '21%';

                                /* Div donde se muestra el recorte*/
                                divCanvas.style.transform = 'scale(0.75)';
                                divCanvas.style.top = '55%';

                                /* Div donde se encuentra el check*/
                                divCheck.style.top = '125%';
                            } else {
                                /* Div donde se encuentra el crop*/
                                reactCrop.style.transform = 'scale(0.5)';
                                reactCrop.style.bottom = '24%';

                                /* Div donde se muestra el recorte*/
                                divCanvas.style.transform = 'scale(0.65)';
                                divCanvas.style.top = '45%';

                                /* Div donde se encuentra el check*/
                                divCheck.style.top = '110%';
                            }
                        }
                    }
                } else {
                    /* Div donde se muestra el recorte*/
                    divCanvas.style.top = '38%';

                    /* Div donde se encuentra el check*/
                    divCheck.style.top = '95%';

                    if (window.innerHeight > 657) {
                        if (isAndroid) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.75)';
                            reactCrop.style.bottom = '13%';
                        } else {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.75)';
                            reactCrop.style.bottom = '15%';
                        }


                    } else if (window.innerHeight > 640 && window.innerHeight <= 657) {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.75)';
                        reactCrop.style.bottom = '14.5%';

                    } else if (window.innerHeight === 640) {
                        /* Div donde se encuentra el crop*/
                        reactCrop.style.transform = 'scale(0.7)';
                        reactCrop.style.bottom = '15%';

                    } else if (window.innerHeight >= 516 && window.innerHeight < 572 && window.innerWidth <= 320) {
                        if (isAndroid) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.57)';
                            reactCrop.style.bottom = '22.5%';

                            /* Div donde se muestra el recorte*/
                            divCanvas.style.transform = 'scale(0.85)';
                            divCanvas.style.top = '45%';

                            /* Div donde se encuentra el check*/
                            divCheck.style.top = '100%';
                        }
                    } else if (window.innerHeight > 568) {

                        if (isAndroid) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.66)';
                            reactCrop.style.bottom = '18.5%';
                        } else {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.65)';
                            reactCrop.style.bottom = '17%';

                            /* Div donde se muestra el recorte*/
                            divCanvas.style.transform = 'scale(0.50)';
                        }
                    } else {
                        if (isAndroid) {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.47)';
                            reactCrop.style.bottom = '27%';

                            /* Div donde se muestra el recorte*/
                            divCanvas.style.top = '45%';

                            /* Div donde se encuentra el check*/
                            divCheck.style.top = '100%';
                        } else {
                            /* Div donde se encuentra el crop*/
                            reactCrop.style.transform = 'scale(0.55)';
                            reactCrop.style.bottom = '23.5%';
                        }

                        /* Div donde se muestra el recorte*/
                        divCanvas.style.transform = 'scale(0.7)';
                    }
                }

                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imageRef.current) {
            return;
        }

        const image = imageRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const onLoad = useCallback((img) => {
        imageRef.current = img;
    }, []);

    const mostrarRecorte = () => {
        setDoCrop(false);
        let instruccion = document.getElementById("instruccion");
        let reactCrop = document.querySelector('.ReactCrop');
        let divCanvas = document.getElementById("div-canvas");
        let divCheck = document.getElementById("div-check");
        switch (tipo) {
            case "identificacion":
                instruccion.innerHTML = "<b>Verifica</b> tu identificación";
                break;
            case "comprobante":
                instruccion.innerHTML = "<b>Verifica</b> tu comprobante";
                break;
            default:
                break;
        }

        reactCrop.setAttribute("hidden", "");
        divCanvas.removeAttribute("hidden");
        divCheck.removeAttribute("hidden");

    }

    const generarRecorte = (crop, canvas) => {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            (blob) => {
                enviarImagen(blob);
            },
            'image/png',
            1
        );

        evento('Captura ID', 'Click', { description: `GUARDAR (${side.toUpperCase()})` }, true);
    };

    return (
        <Fragment>
            <div className="module_container overflow_hddn fondo_negro" style={{ position: 'fixed' }}>
                <div className="container_grid">
                    <div className="module_title">
                        <p id="instruccion"></p>
                    </div>
                    <div className="div_relative">
                        <ReactCrop
                            src={imageUrl}
                            crop={crop}
                            onChange={newCrop => setCrop(newCrop)}
                            onImageLoaded={onLoad}
                            onComplete={newCrop => setCompletedCrop(newCrop)}
                            keepSelection={true} />
                        <div id="div-canvas" className="div_relative" hidden>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    width: Math.round(completedCrop?.width ?? 0),
                                    height: Math.round(completedCrop?.height ?? 0)
                                }}
                            />
                        </div>
                        <div id="div-check" className="check_container div_relative" hidden>

                            <form>
                                {(isIOS || isSafari) ?
                                    <div>
                                        <label htmlFor="check_terms" className="custom-checkbox white_label">
                                            <input id="check_terms" type="checkbox" onChange={e => { setGuardar(!guardar); setCapturing(!capturing); evento('Captura ID', 'Click', { description: `Confirmo que la captura es clara y los datos legibles (${side.toUpperCase()})` }, true); }} />
                                            <span className="checkbox-decorator"><span className="check"></span></span>
                                            Confirmo que la captura es clara y los datos legibles.
                                        </label>
                                    </div>
                                    :
                                    <div className="checkbox">
                                        <label htmlFor="check_terms" className="white_label">
                                            <input id="check_terms" type="checkbox" onChange={e => { setGuardar(!guardar); setCapturing(!capturing); evento('Captura ID', 'Click', { description: `Confirmo que la captura es clara y los datos legibles (${side.toUpperCase()})` }, true); }} />
                                            <span className="checkbox-decorator"><span className="check"></span></span>
                                            Confirmo que la captura es clara y los datos legibles.
                                        </label>
                                    </div>
                                }
                            </form>

                        </div>
                    </div>
                    <div>
                        {doCrop ?
                            <div className="module_buttons">
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => mostrarRecorte()}>AJUSTE LISTO</button>
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => reload()}>CAPTURAR DE NUEVO</button>

                            </div>
                            :
                            <div className="module_buttons">
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100", (capturing ? "gray_bg_color" : "main_bg_color"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => generarRecorte(crop, previewCanvasRef.current)} disabled={!guardar}> GUARDAR</button>
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => reload()}>CAPTURAR DE NUEVO</button>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CropPhoto;