import React, { useState, useEffect } from 'react'
/* Funciones */
import { isMobile } from 'react-device-detect'
import { configStyle } from '../../services/configStyle'
/* Componentes */
import Header from "../../components/headerNA"
import Ayuda from "../../pages/ayuda"


const Index = ({ uuidTransaccion, dataOtorgante, estado }) => {

    const [showHelp, setShowHelp] = useState(false)

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    const generarTitulo = () => {
        switch (estado) {
            case 'cancelado':
                return 'Proceso cancelado'
            case 'fallido':
                return '¡Lo sentimos!'
            case 'exitoso':
                return 'Proceso terminado'
            default:
                return 'Sin resultado'
        }
    }

    const generarTexto = () => {
        switch (estado) {
            case 'cancelado':
                return 'Has cancelado el proceso, puedes intentarlo de nuevo cuando lo desees.'
            case 'fallido':
                return 'Has superado el número máximo de intentos para este proceso, inténtalo nuevamente más tarde.'
            case 'exitoso':
                return 'Has completado tu proceso de validación exitosamente.'
            default:
                return 'Sin texto'
        }
    }

    return (
        <>
            <div className='main_gradient container-flex screen_in_front'>
                <Header show={() => showHelp(true)} />
                <div className='container-center-flex'>
                    <div className='main_text_container '>
                        <h1 className='animate__animated'>{generarTitulo()}</h1>
                        <br />
                        <p className='animate__animated'>{generarTexto()}</p>
                        <br />
                        <br />
                        <div className='div-text-center'>
                            <p className='animate__animated'>
                                Folio: <br /> <b>{uuidTransaccion.split('-')[0]}</b>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='container-bottom-flex'>
                    <div className={isMobile ? 'action_buttons' : 'action_buttons_desk'}>
                        {/* <a className='btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s' href={'/'}>
                            TERMINAR
                        </a> */}
                    </div>
                </div>
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
        </>
    );
}

export default Index;
