import React from "react";
import { isMobile } from 'react-device-detect';

const ErrorMessageAlert = (props) => {
    const { errorMessage, functionOnCancel, functionOnRetry } = props;

    return (
        <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
            <div className={['modal-dialog', isMobile ? 'modal-center' : 'modal-center-desktop'].join(' ')} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"><b>Lo sentimos</b></h5>
                    </div>
                    <div className="modal-body">
                        <p>{errorMessage}</p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" data-dismiss="modal" onClick={functionOnCancel}>CANCELAR</button>
                        <button type="button" onClick={functionOnRetry} className="btn btn-raised btn-primary main_bg_color">REINTENTAR</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorMessageAlert;