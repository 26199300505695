/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { enviarCorreoAceptacion, obtenerScore, getDocuments, finalUpdateScore } from '../services/api.js'
import { configStyle, obtenerValorConfig, obtenerValorUser, replaceValue } from '../services/configStyle'
import Loader from "../components/loader";
import { isMobile, isIOS } from 'react-device-detect';
import { hayExcepcion, status, statusError, statusData, regresarJson, sendData, evento, validarRedireccionamiento } from '../services/data';
import Ayuda from './ayuda';
import Email from 'react-email-autocomplete'

let ConsultaHistorial = (props) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [statError, setstatError] = useState(false);
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState('-')
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataFace, setDataFace] = useState({})
    const [correo, setCorreo] = useState('')
    const [correoMsj, setCorreoMsj] = useState(false)
    const [focusCorreo, setFocusCorreo] = useState(false)
    const [isFlag, setFlag] = useState('RED')
    const { ruta } = useParams();
    const [showHelp, setShowHelp] = useState(false);
    const [contador, setContador] = useState(0)
    const [flag] = useState(false);
    const intentos = 15;
    const [uuidTrx, setUuidTrx] = useState('-')
    const [getResponseFlag, setGetResponseFlag] = useState(false)
    const [redirectEnable, setRedirectEnable] = useState(false)
    // const [respuestaFinalLifeCycle, setRespuestaFinalLifeCycle] = useState(false)
    // const [respuestaFinalPivote, setRespuestaFinalPivote] = useState(false)

    const customDomains = ['elektra.com.mx', 'circulodecredito.com.mx', 'gmail.com', 'outlook.com',
        'hotmail.com', 'yahoo.com', 'dicio.ai', 'proton.me', 'mail.aol.com', 'live.com', 'google.com',
        'googlemail.com', 'mail.com']

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        if (window.opener) console.log("window.opener");

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) {
            setUuidTrx(uuidTrx)
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            let responseV = validarRedireccionamiento(JSON.parse(dataOtorganteLS))
            setRedirectEnable(responseV)
        }
        let dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS != null) {
            setDataFace(JSON.parse(dataFaceLS))
        }

        actualizarScore()
        status("consulta_score_page", "Validación de Score")

    }, [])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    // useEffect(() => {
    //     if(respuestaFinalLifeCycle && respuestaFinalPivote) window.close()
    // }, [respuestaFinalLifeCycle, respuestaFinalPivote])

    // const printText = () => {
    //     let textToPrint = "";
    //     let textStorage = obtenerValorConfig(dataOtorgante, "TEXTO_DE_CONSENTIMIENTO_3")
    //     if (textStorage !== "") {
    //         textToPrint = replaceValue(textStorage, "{USUARIO}", obtenerValorUser(dataUser, "nombre"))
    //         textToPrint = replaceValue(textToPrint, "{OTORGANTE}", obtenerValorConfig(dataOtorgante, "RAZON_SOCIAL"))
    //     } else {
    //         textToPrint = "Resultados de Datos Biometricos capturados:"
    //     }
    //     return textToPrint
    // }

    useEffect(() => {
        //console.log("contador", contador);
        setTimeout(() => {
            if (!flag && parseInt(contador) < intentos && parseInt(contador) > 0) {
                obtenerScoreUser()
            } else {
                if (parseInt(contador) > intentos) {
                    setFlag('RED')
                    setGetResponseFlag(true)
                    statusError("score_check", "Sin valores en el score", "error")
                }
                setLoading(false)
            }
        }, 200);

    }, [contador])

    const obtenerScoreUser = async () => {
        setLoading(true)
        let uuidUser = localStorage.getItem("uuidUser")
        let tipo = '';
        let informacion = {};
        //console.log("entre a obtener");
        obtenerScore(uuidUser, !isMobile).then((response) => {
            //console.log("Response200",response);
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Obtener Score', 'Success', informacion, true);
                if (response.data.payload.flag) {
                    let data = { flag: response.data.payload.flag }
                    statusData("consulta_score", data)
                    let b = (response.data.payload.flag).toUpperCase()
                    setFlag(b)
                    setGetResponseFlag(true)
                    setLoading(false)
                    let campos = JSON.stringify(response.data.payload.Fields)
                    localStorage.setItem("fields", campos);
                    localStorage.setItem("flag", response.data.payload.flag);
                } else {
                    console.log("sin bandera");
                    setContador(parseInt(contador) + 1)
                }
            }

        }).catch((error) => {
            console.log("errorBandera:", error);
            setContador(parseInt(contador) + 1)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Score', tipo, informacion, false);

        })
    }

    const statusF = (error) => {
        //console.log("entre a status ine");
        statusError("email_check", error, "error")
    }

    const enviarCorreo = (e) => {
        setLoading(true)
        setCorreoMsj(false)
        let tipo = '';
        let informacion = {};
        let objeto = {};
        if (isFlag === "GREEN") {
            objeto.tipo_envio = 'COMPLETO';
            // objeto.estado_flujo = "COMPLETADO"
        } else {
            objeto.tipo_envio = 'SEMAFORO';
            // objeto.estado_flujo = "CANCELADO"
        }
        objeto.semaforo = isFlag.toLocaleLowerCase();
        objeto.uuidUser = uuid;
        objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
        objeto.email = correo;
        // if (localStorage.getItem("completado") === true) {
        //     objeto.estado_flujo = "CANCELADO"
        // } else {
        //     objeto.estado_flujo = "COMPLETADO"
        // }
        localStorage.setItem("correoElectronico", correo);
        objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
        objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
        objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
        objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
        objeto.detailsScore = JSON.parse(localStorage.getItem('fields')) || [];
        let fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_");
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_");
        objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
        objeto.originOS = isIOS ? 'ios' : 'android';
        objeto.coincidencia = (dataFace.match ? "Coincide" : "Requiere validación");
        enviarCorreoAceptacion(objeto).then((response) => {
            if (response.status === 201) {
                //console.log(response);
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Envío Correo', 'Success', informacion, true);
                statusData("email_check", { email: correo })
                if (regresarJson()) {
                    dataLinks()
                } else {
                    setTimeout(() => {
                        updateStatus();
                    }, 500);
                }
            }
            else {
                setstatError(true)
                statusF(response.data.payload);
            }

        }).catch((error) => {
            console.error(error);
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Envío Correo', tipo, informacion, false);
            setstatError(true)
            statusF(error);

        })
        evento('Validación de Score', 'Click', { description: 'TERMINAR', status: 'FINALIZADO', correo: correo }, true);
    }

    const convertdataJSON = () => {
        var fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        var jsonObj = {};
        jsonObj.uuidUser = uuid;
        jsonObj.uuidTrx = uuidTrx;
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_");
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_");
        let uuidC = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
        jsonObj.custom_uuid = uuidC;
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user"));
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || "";
        jsonObj.detailsScore = JSON.parse(localStorage.getItem('fields')) || [];
        jsonObj.email = correo;
        var res = JSON.stringify(jsonObj)
        ////console.log(res);
        return jsonObj;
    }

    const dataLinks = async () => {
        //console.log(event_t);
        let tipo = '';
        let informacion = {};
        getDocuments().then((response) => {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Obtener Documentos', 'Success', informacion, true);
            if (response.status === 200) {
                if (response.data.payload.links) {
                    let asd = JSON.stringify(response.data.payload.links)
                    localStorage.setItem('links', asd);
                }
                setTimeout(() => {
                    updateStatus();
                }, 500);
            }
        }).catch((error) => {
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Documentos', tipo, informacion, false);
        })
    }

    const updateStatus = async () => {
        let res = convertdataJSON();
        let event_t = JSON.stringify(res)
        var json_final = {};
        let statu = "OK"
        if (isFlag === "YELLOW") {
            statu = "FAIL"
        }
        json_final = {
            "status": statu,
            "step": "finished",
            "description": event_t,
            "finished": true
        }
        let event = JSON.stringify(json_final);
        await sendData({ 'step': 'finalizado', 'event': event });
        // setRespuestaFinalLifeCycle(true)
        localStorage.setItem("completado", "true");
        statusFinal();
    }

    const statusFinal = () => {
        if (window.opener) {
            let uuidTrx = localStorage.getItem("uuidTrx");
            let event_t = "OK"
            if (regresarJson()) {
                let res = convertdataJSON();
                event_t = (res !== "") ? JSON.stringify(res) : ""
            }
            window.opener.postMessage(JSON.stringify({
                step: "completed",
                result: event_t,
                transaccion: uuidTrx,
            }), localStorage.getItem("externalReference"))
            // setRespuestaFinalPivote(true)
            // setTimeout(() => {
            //     window.close();
            // }, 1000);
        } else {
            if (validarRedireccionamiento(dataOtorgante)) {
                setLoading(false)
                setTimeout(() => {
                    history.push('/' + apiKey)
                }, 300);
            }
        }
    }

    const actualizarScore = () => {
        let tipo = '';
        let informacion = {};
        setLoading(true);
        let objeto = { createScore: true }
        let scoreObject = JSON.parse(localStorage.getItem("scoreObject"));
        if (scoreObject != null) {
            objeto = { ...objeto, ...scoreObject}
        }
        finalUpdateScore(objeto).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Actualizar Score', 'Success', informacion, true);
                setTimeout(() => {
                    obtenerScoreUser();
                }, 300);
            }
        }).catch((error) => {
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Actualizar Score', tipo, informacion, false);
        })
    }

    return (
        <div className="main_gradient">
            <Header ruta="finalizado" show={() => setShowHelp(true)} />
            <div className="main_text_container">
                {getResponseFlag &&
                    <>
                        {
                            (isFlag !== "RED") ?
                                <h1 className="animate__animated animate__fadeIn animate__delay-1s">¡Muchas gracias!</h1>
                                :
                                <h1 className="animate__animated animate__fadeIn animate__delay-1s">Proceso no exitoso</h1>
                        }
                        {
                            (isFlag === "GREEN") ?
                                <div className="animate__animated animate__fadeIn animate__delay-2s">
                                    <p>{redirectEnable ? 'Has completado tu solicitud exitosamente,' : 'Para continuar con tu solicitud'}  enviaremos el resultado del proceso a: </p>
                                </div>
                                :
                                (isFlag === "YELLOW") ?
                                    <div className="animate__animated animate__fadeIn animate__delay-2s">
                                        <p>Tu solicitud esta siendo procesada por nuestro equipo de expertos, te notificaremos el resultado en las proximas horas a: </p>
                                        <br />

                                    </div>
                                    :
                                    <div className="animate__animated animate__fadeIn animate__delay-2s">
                                        <p>Lo sentimos tu proceso <b>no fue aceptado</b>, te sugerimos <b>verificar tu documentación</b> e intentarlo mas adelante siguiendo <b>detenidamente las instrucciones</b>.</p>
                                        <p>Si desea mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{uuidTrx.split("-")[0]}</b></p>
                                        <br />

                                    </div>

                        }
                        {
                            (isFlag !== "RED") &&
                            <div className="ocr_data_display padding-five_percent animate__animated animate__fadeIn animate__delay-2s">
                                <div className={["form-group", "bmd-form-group", (focusCorreo ? "is-focused" : "")].join(" ")}>
                                    <label htmlFor="emailInput" className="bmd-label-floating">Correo electrónico:*</label>
                                    {/*  <input type="email" inputMode="email" name="correo" className="form-control" id="emailInput" defaultValue={correo} onChange={e => setCorreo(e.target.value)} onFocus={e => {
                                        setFocusCorreo(true)
                                    }} onBlur={e => {
                                        if (correo === "") {
                                            setFocusCorreo(false)
                                        }
                                    }} /> */}
                                    <Email type="email" inputMode="email" name="correo" className="form-control" id="emailInput" defaultValue={correo} onChange={e => setCorreo(e.target.value)} onFocus={e => {
                                        setFocusCorreo(true)
                                    }} onBlur={e => {
                                        setCorreo(e.target.value)
                                        if (correo === "") {
                                            setFocusCorreo(false)
                                        }
                                    }}
                                        domains={customDomains} />
                                </div>
                            </div>
                        }
                        {
                            (isFlag !== "RED") ?
                                <div className="main_icon_container animate__animated animate__fadeIn animate__delay-2s">
                                    <br />
                                    <a id="linkApp" href={"" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser")}>
                                        {((isFlag === "YELLOW") ? <img src={process.env.PUBLIC_URL + 'images/check_warning.svg'} alt="" /> : <img src={(obtenerValorConfig(dataOtorgante, 'ICONO_ID_CHECK', "") != "") ? obtenerValorConfig(dataOtorgante, 'ICONO_ID_CHECK', process.env.PUBLIC_URL + '/images/check.svg') : process.env.PUBLIC_URL + '/images/check.svg'} alt="" />)}
                                    </a>
                                    <br /><br />
                                    <p className="text-center" >Folio: <br />
                                        <b>{uuidTrx.split("-")[0]}</b>
                                    </p>
                                    <div className="action_buttons">
                                        <button type="button" onClick={(e) => { setCorreoMsj(true); evento('Validación de Score', 'Click', { description: 'TERMINAR' }, true); }} disabled={!correo || loading} className="btn btn-raised btn-primary forcewidth100 main_bg_color">{redirectEnable ? 'TERMINAR' : 'CONTINUAR'}</button>
                                    </div>
                                </div>
                                :
                                <div className="main_text_container animate__animated animate__fadeIn animate__delay-2s" style={{ padding: "0 10%" }}>
                                    <div className="help_container">
                                        <p className="help_mail"><b>Escríbenos a:</b>
                                            <br />
                                            <span className="d-flex align-items-center">
                                                <i className="arroba"></i><a href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a>
                                            </span>
                                        </p>
                                        <p className="help_phone"><b>Llámanos al:</b>
                                            <br />
                                            <span className="d-flex align-items-center">
                                                <i className="phone"></i><a href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a>
                                            </span>
                                        </p>
                                        <p className="help_whats"><b>Hablemos en:</b>
                                            <br />
                                            <span className="d-flex align-items-center">
                                                <i className="whatsapp"></i><a rel="external" href={"https://wa.me/" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL') + "?text=Tuve%20problemas%20en%20el%20proceso%20mi%20ID%20de%20transacci%C3%B3n%20es%20%20" + uuid.split("-")[0]}>WhatsApp</a>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                        }
                    </>
                }
            </div>

            {(loading) ? <Loader /> : ("")}
            {(correoMsj) ?
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className={['modal-dialog', isMobile ? 'modal-center' : 'modal-center-desktop'].join(' ')} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{(isFlag === "GREEN") ? "¡Muy bien hecho!" : "¡Bien hecho!"}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{(isFlag === "GREEN") ? "En este momento enviaremos el reporte al correo " + correo + " por favor asegúrate que es correcto o regresa y corrígelo." : "Te notificaremos tu resultado en las próximas horas al correo " + correo + " por favor asegúrate que es correcto o regresa y corrígelo."}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={e => setCorreoMsj(false)} className="btn btn-primary forcewidth100 main_color">REGRESAR</button>
                                <button type="button" onClick={e => enviarCorreo()} className="btn btn-raised btn-primary main_bg_color">{redirectEnable ? 'TERMINAR' : 'CONTINUAR'}</button>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            <Footer />
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </div>
    )
}

export default ConsultaHistorial