import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import NumberFormat from 'react-number-format'
import {
    hayExcepcion, agregarInfo, statusData, formatoFechaOcr,
    formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, evento, statusError,
    encolarPeticionRenapo, generateZip, validarRedireccionamiento
} from '../../services/data'
import Loader from '../../components/loader'
import { isIOS } from 'react-device-detect'
import Footer from '../../components/footer'
import CancelarFlujo from '../../components/cancelar_flujo'

let FormularioPasaporte = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        nombreCompleto: '',
        fechaNacimiento: '',
        vigenciaPasaporte: '',
        curp: ''
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esPermanente, setEsPermanente] = useState(false);
    const [esMigratoria, setEsMigratoria] = useState(false);

    const [fechaNacimiento, setfechaNacimiento] = useState("");
    const [fechaVigencia, setfechaVigencia] = useState("");
    const [esVigente, setEsVigente] = useState(true);
    const [sendForm, setSendForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFooter, setShowFooter] = useState(true)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [uuidTransaccion, setUuidTransaccion] = useState('')

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }

        let uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) {
            setUuidTransaccion(uuidTransaccionLS)
        }
        const dataOCRFrontLS = localStorage.getItem("ocrFront")
        const dataOCRBackLS = localStorage.getItem("ocrBack")
        const ocrFront = JSON.parse(dataOCRFrontLS)

        if (dataOCRFrontLS) {
            if (ocrFront.nombreCompleto) {
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombreCompleto"]: ocrFront.nombreCompleto }));
            } else {
                if (dataOCRBackLS) {
                    const ocrBack = JSON.parse(dataOCRBackLS)
                    if (ocrBack.nombreCompletoMRZ) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["nombreCompleto"]: ocrBack.nombreCompletoMRZ }));
                    }
                }
            }
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
                setfechaNacimiento(ocrFront.fechaNacimiento)
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            let vigenciaC = ''
            if (ocrFront.documentoPermanente) {
                setEsPermanente(true)
                vigenciaC = 'PERMANENTE'
                if (ocrFront.identificacionMigratoria) {
                    setEsMigratoria(true)
                }
            } else {
                if (ocrFront.vigencia) {
                    if (ocrFront.identificacionPasaporte) {
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia
                        if (ocrFront.identificacionPasaporte) {
                            if (ocrFront.vigencia.includes(' '))
                                vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                            else
                                vigenciaC = ocrFront.vigencia
                        }
                    } else if (ocrFront.identificacionMigratoria) {
                        setEsMigratoria(true)
                        vigenciaC = ocrFront.vigencia
                    }
                    setfechaVigencia(ocrFront.vigencia)
                }
            }
            setInputsValue(inputsValue => ({ ...inputsValue, ['vigenciaPasaporte']: vigenciaC }));
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = null;
        let value = event.target.value.trim().toUpperCase();
        if (event.target.name === "fechaNacimiento") {
            setfechaNacimiento(event.target.value)
        }
        if (event.target.name === "vigenciaPasaporte") {
            setfechaVigencia(event.target.value)
        }
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        } else {
            validacion = validarCampo(value, event.target.name);
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        setSendForm(true);
        let fechaNacimientoValue = document.querySelector('#fechaNacimiento').value;
        inputsValue.fechaNacimiento = fechaNacimientoValue;
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "false") === "true") {
                setLoading(true)
                encolarPeticionRenapo(inputsValue.curp)
            }
            let infoFormulario = []
            agregarInfo(dataUser, { description: "nombreCompleto", value: inputsValue.nombreCompleto });
            infoFormulario.push({ description: "nombreCompleto", value: inputsValue.nombreCompleto })
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp })
            let inputVigenciaPasaporte = document.querySelector('#vigenciaPasaporte')
            if (inputVigenciaPasaporte && inputsValue.vigenciaPasaporte !== 'PERMANENTE') {
                inputsValue.vigenciaPasaporte = inputVigenciaPasaporte.value
                infoFormulario.push({ description: "vigencia", value: inputVigenciaPasaporte.value })
                agregarInfo(dataUser, { description: "vigencia", value: inputVigenciaPasaporte.value })
                let fechaActual = inputVigenciaPasaporte.value
                let dateParts = fechaActual.split("/")
                let fechaV = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
                if (fechaV >= Date.now()) {
                    setTimeout(() => {
                        sendData(infoFormulario)
                        setLoading(false)
                        if (hayExcepcion()) {
                            history.push('/clausula')
                        } else {
                            history.push('/identificacion_edit')
                        }
                    }, 500)
                } else {
                    setLoading(false)
                    setEsVigente(false)
                }
            } else {
                infoFormulario.push({ description: "vigencia", value: inputsValue.vigenciaPasaporte })
                agregarInfo(dataUser, { description: "vigencia", value: inputsValue.vigenciaPasaporte })
                setTimeout(() => {
                    sendData(infoFormulario)
                    setLoading(false)
                    if (hayExcepcion()) {
                        history.push('/clausula')
                    } else {
                        history.push('/identificacion_edit')
                    }
                }, 500)
            }

        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }

    const statusSE = () => {
        evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)
    }

    const terminarFlujo = async (event) => {
        if (event) event.preventDefault()
        let data = "Cancelado"
        setLoading(true)
        const responseZip = await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("datos_personales_ocr", data, "cancelado")
        evento('Datos personales OCR', 'Click', { description: 'TERMINAR', status: data }, true)
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.push("/" + apiKey)
                setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
            }
        }
    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase()

    return (
        <>
            <form id="FormularioPasaporte">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombreCompleto !== "") ? "is-focused" : ""), inputsErrors.nombreCompleto && 'error'].join(" ")}>
                        <label htmlFor="nombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="nombreCompleto" name="nombreCompleto" defaultValue={inputsValue.nombreCompleto} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                            setShowFooter(false)
                        }}
                            onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.nombreCompleto === "") {
                                    setFocusNombre(false)
                                }
                                setInputActive(false)
                            }} />
                        {inputsErrors.nombreCompleto && (
                            <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCompleto}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control" id="fechaNacimiento" name="fechaNacimiento" defaultValue={inputsValue.fechaNacimiento} value={fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                            setShowFooter(false)
                        }}
                            onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.fechaNacimiento === "") {
                                    setFocusNacimiento(false)
                                }
                                setInputActive(false)
                            }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    {!esPermanente &&
                        <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigenciaPasaporte !== "") ? "is-focused" : ""), inputsErrors.vigenciaPasaporte && 'error'].join(" ")}>
                            <label htmlFor="vigenciaPasaporte" className="bmd-label-floating">Vencimiento identificación <small>(DD/MM/AAAA)</small>:</label>
                            <NumberFormat format="##/##/####" className="form-control none_border" id="vigenciaPasaporte" name="vigenciaPasaporte" defaultValue={inputsValue.vigenciaPasaporte} value={fechaVigencia} onChange={handleChange} onFocus={e => {
                                setFocusVigencia(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }}
                                onBlur={e => {
                                    setShowFooter(true)
                                    if (inputsValue.vigenciaPasaporte === "") {
                                        setFocusVigencia(false)
                                    }
                                    setInputActive(false)
                                }} />
                            {inputsErrors.vigenciaPasaporte && (
                                <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigenciaPasaporte}</span>
                            )}
                        </div>}
                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                        {
                            (esMigratoria) ?
                                <input type="text" className="form-control uppercase" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                    setShowFooter(false)
                                }}
                                    onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.curp === "") {
                                            setFocusCurp(false)
                                        }
                                        setInputActive(false)
                                    }} maxLength="19" />
                                :
                                <input type="text" className="form-control uppercase" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                    setShowFooter(false)
                                }} onBlur={e => {
                                    setShowFooter(true)
                                    if (inputsValue.curp === "") {
                                        setFocusCurp(false)
                                    }
                                    setInputActive(false)
                                }} />
                        }
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>
                </div>
                {(!esVigente) ? (
                    <div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Identificación no valida </h5>
                            <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <Link to={{
                                    pathname: "/identificacion",
                                    state: { passport: esPassport }
                                }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CAPTURAR NUEVAMENTE</Link>
                                <button type='button' className="btn btn-secondary forcewidth100" onClick={event => terminarFlujo(event)}>TERMINAR</button>
                            </div>
                        </div>
                    </div>) : ("")}
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={statusSE}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
            {loading && <Loader />}
            {showFooter && <Footer />}
            {showCancelScreen  && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'}/>}
        </>
    )
}

export default FormularioPasaporte