import React, { useState, useEffect } from "react";
import { isIOS, isMobile } from "react-device-detect";
import Header from "../header";
import { useHistory } from "react-router-dom"
import { configStyle } from "../../services/configStyle";
import { cancelarRequestRenapo, cancelarRequestIne, cancelarRequest } from '../../services/api';
import { statusError, sendEventClick, generateZip, cancelarINE, cancelarRENAPO, validarRedireccionamiento } from '../../services/data'
import CancelarFlujo from "../../pages/cancelar_flujo";
import Loader from "../loader";
import Ayuda from "../../pages/ayuda";
import LimitIntentsAlert from "../alerts_modals/limit_intents";

const IndicationLiveness = () => {
    const [showHelp, setShowHelp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cancelProcess, setCancelProcess] = useState(false);
    const [showCancelScreen, setShowCancelScreen] = useState(false);
    const [bloqueo, setBloqueo] = useState(false);
    const [estado, setEstado] = useState('');
    const [pathname, setPathname] = useState('');
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [uuidTransaccion, setUuidTransaccion] = useState('');
    const history = useHistory();
    let apikey = localStorage.getItem("apikeyOtorgante") ? localStorage.getItem("apikeyOtorgante") : "";

    useEffect(() => {
        const dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            setDataOtorgante(dataOtorganteJSON)
        }

        setPathname(window.location.pathname)

        let uuidTrx = localStorage.getItem('uuidTrx')
        setUuidTransaccion(uuidTrx)

        let block = localStorage.getItem("bloqueo")
        console.log('BLOQUEO ', block);
        if(block == "true") setBloqueo(true);
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const cancelarFLujo = async () => {
        setLoading(true)
        cancelarINE();
        cancelarRENAPO();
        cancelarRequest();
        cancelarRequestRenapo()
        cancelarRequestIne()
        sendEventClick('Captura Video Token', 'SÍ', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("captura_video_token", 'Cancelado', "cancelado")
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.push('/' + apikey)
                    setLoading(false)
                }, 1000);
            } else {
                setLoading(false);
                setShowCancelScreen(true)
                setCancelProcess(false)
                setEstado('cancelado')
            }
        }
    }

    return (
        <div className="hollo_gif_bx show_id_hold container-flex" style={{ backgroundImage: 'linear-gradient(180deg, var(--color-secondary) 0%, var(--color-primary) 100%)', overflowY: 'scroll' }}>  
            <Header show={() => setShowHelp(true)} />
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            <div className="main_text_container text-left custom_padding">
                <h1 className="animate__animated text-left">Confirmación de identidad</h1>
                <p>Mantén tu <b>rostro visible, sin más personas</b> en la toma, <b>no</b> utilices <b>gorra</b> y <b>evita</b> colocarte <b>frente</b> a <b>fuentes</b> de luz.<br /></p>
                <br></br>
            </div>
            <div className='container_selfie_instructions container-flex'>
                <div className='row'>
                    <div className={`${isMobile ? 'col-12  div-text-center' : 'col-6 div-text-right'}`}>
                        <img className={`${isMobile ? 'img_selfie_instructions' : 'img_selfie_instructions_desktop'}`} src={`images/componente_selfie/${apikey === 'gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi' ? 'selfie_error_dy1@2x.png' : 'selfie_error_g1@2x.png'}`} alt='' />
                    </div>
                    <div className={`${isMobile ? 'col-12  div-text-center' : 'col-6 div-text-left'}`}>
                        <img className={`${isMobile ? 'img_selfie_instructions' : 'img_selfie_instructions_desktop'}`} src={`images/componente_selfie/${apikey === 'gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi' ? 'selfie_error_dy2@2x.png' : 'selfie_error_g2@2x.png'}`} alt='' />
                    </div>
                </div>
            </div>

            <div className="action_buttons animate__animated animate__fadeIn animate__delay-1s custom_padding">
                <button onClick={() => history.push("/liveness")} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CONTINUAR</button>
                <button onClick={() => setCancelProcess(true)} className="btn btn-primary forcewidth100 main_color">CANCELAR</button>
            </div>

            {(cancelProcess) &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={() => setCancelProcess(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setCancelProcess(false); sendEventClick('Captura Video Token', 'NO', {}); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={cancelarFLujo} className="btn btn-raised btn-primary main_bg_color">SÍ</button>
                            </div>
                        </div>
                    </div>
                </div>
            }    
            {showCancelScreen && 
                <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={estado} />
            }
            {loading && <Loader />}
            {bloqueo == true && <LimitIntentsAlert 
                uuidTransaccion={uuidTransaccion} 
                functionOnOut={() => {history.push('/'+ apikey)}} 
                dataOtorgante={dataOtorgante}
                pathname={pathname}
            /> }
        </div>
    )

}

export default IndicationLiveness;