import React, { useState, useCallback, useRef, useEffect } from 'react'
import Cropper from 'react-perspective-cropper';
import { isIOS, isSafari, isMobile, isAndroid } from 'react-device-detect';
import { sendEventClick } from './utilities/events/eventos';
import { obtenerValorConfig } from './utilities/styles/configStyle';
import ImageRotation from 'image-rotation';

let colorPointBGColor = '', imageRotate;
const urlCreator = window.URL || window.webkitURL;

const ComponenteRecorte = ({ tipo, imageUrl, enviarImagen, reload, side, configuration, setShowAlertDimensions }) => {
    const [cropState, setCropState] = useState();
    const [img, setImg] = useState(null);
    const [guardar, setGuardar] = useState(false);
    const [doCrop, setDoCrop] = useState(true);
    const [capturing, setCapturing] = useState(true);
    const [blobImage, setBlobImage] = useState(true);
    const [rotateValue, setRotateValue] = useState(-90)
    const cropperRef = useRef(null);
    const divCropperRef = useRef(null);
    const divInstruccionRef = useRef(null);

    const onDragStop = useCallback((s) => setCropState(s), []);
    const onChange = useCallback((s) => setCropState(s), []);
    useEffect(() => {
        if (configuration.dataOtorgante.length > 0) {
            let colorAcentuacion = obtenerValorConfig(configuration.dataOtorgante, 'COLOR_ACENTUACION', '');
            if (colorAcentuacion) {
                if (colorAcentuacion.includes(',1)')) {
                    colorPointBGColor = colorAcentuacion.replace(',1)', ',0.5)');
                } else {
                    colorPointBGColor = colorAcentuacion.replace(')', ',0.5)');
                }
            }
        }
        return () => { };
    }, [configuration]);

    useEffect(() => {
        if (divInstruccionRef.current) divInstruccionRef.current.innerHTML = "<b>Ajusta</b> tu identificación";
        return () => { };
    }, [divInstruccionRef]);

    const mostrarRecorte = async () => {
        try {
            let imageCanvas = document.querySelector('#imageCanvas');
            if (imageCanvas) imageCanvas.setAttribute('hidden', '');
            const blob = await cropperRef.current.done({ preview: true });
            if (isMobile && (imageCanvas.width > imageCanvas.height)) {
                setShowAlertDimensions(true);
                reload();
            } else {
                setDoCrop(false);
                //setImg(blob);
                if (isMobile) {
                    setBlobImage(blob);
                    rotateImage(blob);
                } else {
                    setImg(blob);
                }
                switch (tipo) {
                    case "identificacion":
                        divInstruccionRef.current.innerHTML = "<b>Verifica</b> tu identificación";
                        break;
                    case "comprobante":
                        divInstruccionRef.current.innerHTML = "<b>Verifica</b> tu comprobante";
                        break;
                    default:
                        break;
                }
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const generarRecorte = () => {
        sendEventClick('Captura ID', `GUARDAR (${side.toUpperCase()})`, {}, configuration);
        enviarImagen(img);
    };

    const descargarImagen = (blob) => {
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = 'captura_ine.png';
        a.click();
    }

    useEffect(() => {
        if (img) {
            let url = urlCreator.createObjectURL(img);
            let imageCanvas = document.querySelector('#imageCanvas');
            let imgPreview = document.querySelector('#imgPreview');
            let divImgPreview = document.querySelector('#div-imgPreview');
            if (divImgPreview && isMobile) {
                divImgPreview.style.width = `${imageCanvas.height}px`;
                divImgPreview.style.height = `${imageCanvas.width}px`;
            }
            if (imageCanvas && imgPreview) {
                imgPreview.removeAttribute('hidden');
                imgPreview.setAttribute('src', url);
                imgPreview.style.position = 'absolute';
                imgPreview.style.width = `${isMobile ? imageCanvas.height : imageCanvas.width}px`;
                imgPreview.style.height = `${isMobile ? imageCanvas.width : imageCanvas.height}px`;
                isMobile && (imgPreview.style.transform = `scale(0.84)`);
            }
        }
        return () => { };
    }, [img]);

    const rotateImage = (image, rotate = -90) => {
        setRotateValue(rotate);
        imageRotate = new ImageRotation(image);
        imageRotate.generate(rotate, 'image/png').then(res => {
            const blob = imageRotate.toBlob(res);
            setImg(blob);
        })
    }

    return (
        <>
            <div className="module_container fondo_negro" style={{ position: 'fixed', overflowY: 'scroll' }}>
                <div className="container-flex">
                    <div className="module_title">
                        <p ref={divInstruccionRef}></p>
                    </div>
                    <div ref={divCropperRef} className="content-container">
                        <Cropper
                            ref={cropperRef}
                            image={imageUrl}
                            onChange={onChange}
                            onDragStop={onDragStop}
                            pointBgColor={colorPointBGColor}
                            pointBorder={`4px solid ${obtenerValorConfig(configuration.dataOtorgante, "COLOR_ACENTUACION")}`}
                            lineColor={obtenerValorConfig(configuration.dataOtorgante, "COLOR_ACENTUACION")}
                            maxWidth={(!isMobile) ? ((window.innerWidth <= 800) ? 640 : 854) : ((window.innerHeight <= 650) ? 240 : 288)}
                            maxHeight={(!isMobile) ? ((window.innerWidth <= 800) ? 360 : 480) : ((window.innerHeight <= 650) ? 320 : 512)}
                        />
                        {(!doCrop && img) && <>
                            <div className='div-rotate-buttons'>
                                {(isMobile) && <button type="button" className="btn btn-raised btn-primary forcewidth100 rotate-button" onClick={() => rotateImage(blobImage, (rotateValue === -90) ? 90 : -90)} disabled={guardar}>ROTAR IMAGEN</button>}
                            </div>
                            <div id="div-check" className="check_container div_relative">
                                <form>
                                    {(isIOS || isSafari) ?
                                        <div>
                                            <label htmlFor="check_terms" className="custom-checkbox white_label">
                                                <input id="check_terms" type="checkbox" onChange={e => { setGuardar(!guardar); setCapturing(!capturing); sendEventClick('Captura ID', `Confirmo que la captura es clara y los datos legibles (${side.toUpperCase()})`, {}, configuration); }} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Confirmo que la captura es clara, los datos legibles y la toma no está de cabeza.
                                            </label>
                                        </div>
                                        :
                                        <div className="checkbox">
                                            <label htmlFor="check_terms" className="white_label">
                                                <input id="check_terms" type="checkbox" onChange={e => { setGuardar(!guardar); setCapturing(!capturing); sendEventClick('Captura ID', `Confirmo que la captura es clara y los datos legibles (${side.toUpperCase()})`, {}, configuration); }} />
                                                <span className="checkbox-decorator"><span className="check"></span></span>
                                                Confirmo que la captura es clara, los datos legibles y la toma no está de cabeza.
                                            </label>
                                        </div>
                                    }
                                </form>

                            </div>
                        </>}
                    </div>
                    <div>
                        {doCrop ?
                            <div className="module_buttons-cropper">
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => mostrarRecorte()}>AJUSTE LISTO</button>
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => reload()}>CAPTURAR DE NUEVO</button>

                            </div>
                            :
                            <div className="module_buttons-cropper">
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100", (capturing ? "gray_bg_color" : "main_bg_color"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={e => generarRecorte()} disabled={!guardar}> CONTINUAR </button>
                                <button type="button" className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} onClick={() => reload()}>CAPTURAR DE NUEVO</button>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>

    )
}

export default ComponenteRecorte;