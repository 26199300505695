import React from 'react';
import './App.css';
import {
  Router,
  Switch,
  Route
} from "react-router-dom";

import Ayuda from './pages/ayuda';
import ConsultaHistorial from './pages/consulta_historial';
import IdentificacionEdit from './pages/identificacion_edit';
import Finalizado from './pages/finalizado';
import PdfTokenInstructions from './pages/pdf_token_instructions';
import PdfTokenError from './pages/pdf_token_error';
import PdfTokenResult from './pages/pdf_token_result';
import PreparacionIne from './pages/preparacion_ine';
import ResultadosIdentificacion from './pages/resultados_identificacion';
import Terminos from './pages/terminos';
import Inicio from './pages/inicio';
import CancelarFlujo from './pages/cancelar_flujo';
import Clausula from './pages/clausula';
import ValidacionDocumento from './pages/validacion_documento';
import AutorizacionSolicitud from './pages/autorizacion_solicitud';
import AutorizacionDocumento from './pages/autorizacion_documento';
// import CameraPhotoIOS from './components/cameraphoto_ios';
import CameraVideoTokenIOS from './components/cameravideotoken_ios';
import CameraPhotoComprobante from './components/cameraphotoComprobante_ios';
import PreparacionComprobante from './pages/preparacion_comprobante';
import { createBrowserHistory } from "history";
import ErrorPantalla from './pages/error_pantalla';
import PasosConfiguracion from './pages/pasos_configuracion';
import PdfToken from './pages/pdf_token';
import PDFVideoToken from './components/pdfvideotoken';
import ValidarMovil from './pages/validar_movil';
import ValidarSMS from './pages/validar_sms';
import Pivote from './pages/pivote';
import CameraPhotoHologram from './components/cameraphoto_hologram';
import IndexComponenteCapturaIdentificacion from './components/captura_identificacion/index';
import Landscape from './components/landscape';
import ComprobarDispositivo from './pages/dispositivos/comprobar_dispositivo';
import AdvertenciaDispositivo from './pages/dispositivos/advertencia_dispositivo';
import Videotoken from './pages/videotoken'
import VideotokenPDF from './pages/videotoken_pdf'
import Iframe from './components/iframe/iframe'
import IndicationLiveness from './components/previous_page/indications_liveness';
import IframePdf from './components/iframe_pdf';
import IndicationLivenessPdf from './components/previous_page/indications_liveness_pdf';

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/terminos">
          <Terminos />
        </Route>
        <Route path="/pasos_configuracion">
          <PasosConfiguracion />
        </Route>
        <Route path="/preparacion_identificacion">
          <PreparacionIne />
        </Route>
        <Route path="/holograma" component={CameraPhotoHologram}>
        </Route>
        {/* <Route path="/identificacion" component={CameraPhotoIOS}>
        </Route> */}
        <Route path="/identificacion" >
          <IndexComponenteCapturaIdentificacion/>
        </Route>
       <Route path="/video_token" component={CameraVideoTokenIOS}>
        </Route>
        <Route path="/selfie">
          <Videotoken />
        </Route>
        <Route path="/pdf_video_token" component={PDFVideoToken}>
        </Route>
        <Route path="/selfie_pdf">
          <VideotokenPDF />
        </Route>
        <Route path="/liveness_pdf">
          <IframePdf />
        </Route>
        <Route path="/resultados_identificacion">
          <ResultadosIdentificacion />
        </Route>
        <Route path="/identificacion_edit">
          <IdentificacionEdit />
        </Route>
        <Route path="/consulta_historial">
          <ConsultaHistorial />
        </Route>
        <Route path="/error_pantalla">
          <ErrorPantalla />
        </Route>
        <Route path="/validacion_documento">
          <ValidacionDocumento />
        </Route>
        <Route path="/autorizacion_solicitud">
          <AutorizacionSolicitud />
        </Route>
        <Route path="/autorizacion_documento">
          <AutorizacionDocumento />
        </Route>
        <Route path="/preparacion_comprobante">
          <PreparacionComprobante />
        </Route>
        <Route path="/comprobante">
          <CameraPhotoComprobante />
        </Route>
        <Route path="/ayuda/:ruta">
          <Ayuda />
        </Route>
        <Route path="/finalizado">
          <Finalizado />
        </Route>
        <Route path="/pdf_token_instructions">
          <PdfTokenInstructions />
        </Route>
        <Route path="/pdf_token_error">
          <PdfTokenError />
        </Route>
        <Route path="/pdf_token_result">
          <PdfTokenResult />
        </Route>
        <Route path="/clausula">
          <Clausula />
        </Route>
        <Route path="/cancelar_flujo/:ruta">
          <CancelarFlujo />
        </Route>
        <Route path="/pdf_token" >
          <PdfToken />
        </Route>
        <Route path="/validar_movil">
          <ValidarMovil />
        </Route>
        <Route path="/validar_sms">
          <ValidarSMS />
        </Route>
        <Route path="/pivote">
          <Pivote />
        </Route>
        <Route path="/inicio">
          <Inicio />
        </Route>
        <Route path="/advertencia_dispositivo">
          <AdvertenciaDispositivo />
        </Route>
        <Route path="/liveness">
          <Iframe />
        </Route>
        <Route path="/preparacion_liveness">
          <IndicationLiveness />
        </Route>
        <Route path="/preparacion_liveness_pdf">
          <IndicationLivenessPdf />
        </Route>
        <Route path="/:apikey">
          <ComprobarDispositivo />
        </Route>
      </Switch>
      <Landscape/>
    </Router>
  );
}

export default App;
