import React from 'react';
import { isIOS } from 'react-device-detect';

const Index = ({permisosDenegados, setErrorPermisos, permisoCamara}) => {
    return (
        <>
            <div className="overlay_box">
                        <div className="alert_box">
                            {
                                (!permisosDenegados) ?
                                    <p className="animate__animated animate__slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está en uso, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                                    :
                                    (isIOS) ?
                                        <p className="animate__animated animate__slideInDown">Por favor <b>acepta el permiso</b> de uso de la camará y da click en volver a intentar para continuar.</p>
                                        :
                                        <p className="animate__animated animate__slideInDown">Revisa las instrucciones para restablecer los <b>permisos de tu navegador</b> y da click en volver a intentar para continuar.
                                            <img src="images/permisos.gif" className="imgPermisos" alt="Reestablecer permisos" />
                                        </p>
                            }
                            <button type="button" onClick={() => {
                                if (permisosDenegados) {
                                    window.location.reload()
                                } else {
                                    setErrorPermisos(false)
                                    permisoCamara()
                                }
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
        </>
    );
}

export default Index;
